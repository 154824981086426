import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
}

export type AwsCredentials = {
  __typename?: 'AWSCredentials'
  accessKeyId: Scalars['String']
  resourceName: Scalars['String']
  secretAccessKey: Scalars['String']
  sessionToken: Scalars['String']
}

export type AwsCredentialsInput = {
  tenantId?: InputMaybe<Scalars['String']>
  type: AwsCredentialsType
}

export enum AwsCredentialsType {
  UploadAsset = 'upload_asset',
  UploadSource = 'upload_source',
}

export type ActivateDomainInput = {
  id: Scalars['String']
}

export type AddPageToMenuInput = {
  id: Scalars['ID']
  menu: PageMenu
}

/** A type that represents a row of media items to be rendered on a page */
export type All = {
  __typename?: 'All'
  /** A media row to be rendered on the page in descending order */
  row: MediaRow
}

/** A type that represents a row of media items to be rendered on a page */
export type AllRowArgs = {
  data: MediaRowInput
}

export type Api = {
  __typename?: 'Api'
  clientId?: Maybe<Scalars['String']>
  clientSecret?: Maybe<Scalars['String']>
  type: ApiType
}

export type ApiInput = {
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  type: ApiType
}

export enum ApiType {
  Notion = 'notion',
  Postgresql = 'postgresql',
}

export type ArchiveProductsInput = {
  active: Scalars['Boolean']
  ids: Array<Scalars['String']>
}

export type AssignChannelInput = {
  categoryId: Scalars['String']
  id: Scalars['String']
}

export type AuthorizeIntegrationDetailInput = {
  code: Scalars['String']
  id: Scalars['String']
}

/** A type that represents a row of media items to be rendered on a page */
export type Billing = {
  __typename?: 'Billing'
  invoices: Array<Maybe<Invoice>>
  paymentMethods: PaymentMethods
}

export type BrowserState = {
  __typename?: 'BrowserState'
  host?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  os?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

export type BrowserStateInput = {
  host?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  os?: InputMaybe<Scalars['String']>
  version?: InputMaybe<Scalars['String']>
}

export type Bundle = Record & {
  __typename?: 'Bundle'
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  items: Array<MediaItem>
  product?: Maybe<Product>
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

/** A type that represents a customer's credit card */
export type Card = {
  __typename?: 'Card'
  brand: CardBrand
  checks?: Maybe<CardChecks>
  country?: Maybe<Scalars['String']>
  expMonth: Scalars['Int']
  expYear: Scalars['Int']
  id: Scalars['ID']
  last4: Scalars['String']
  name?: Maybe<Scalars['String']>
  primary: Scalars['Boolean']
}

export enum CardBrand {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  EftposAu = 'eftpos_au',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Unionpay = 'unionpay',
  Unknown = 'unknown',
  Visa = 'visa',
}

export enum CardCheckResult {
  Fail = 'fail',
  Pass = 'pass',
  Unavailable = 'unavailable',
  Unchecked = 'unchecked',
}

/** A type that represents the results of a card check */
export type CardChecks = {
  __typename?: 'CardChecks'
  addressLine1Check?: Maybe<CardCheckResult>
  addressPostalCodeCheck?: Maybe<CardCheckResult>
  cvcCheck?: Maybe<CardCheckResult>
}

export type Catalog = Entry &
  Record & {
    __typename?: 'Catalog'
    bundle?: Maybe<Bundle>
    bundleDescription?: Maybe<Scalars['String']>
    bundleEnabled: Scalars['Boolean']
    bundleTitle?: Maybe<Scalars['String']>
    /** Describes how to filter the content for the catalog. */
    contentFilter?: Maybe<FilterGroup>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    /** The icon for the catalog. May be rendered in its media row titles. */
    icon?: Maybe<Image>
    id: Scalars['ID']
    name: Scalars['String']
    /** The product used for the bundle price of this collection. */
    product?: Maybe<Product>
    publishOn?: Maybe<Scalars['DateTime']>
    status: EntryStatus
    tags: Array<Maybe<Tag>>
    updatedAt: Scalars['DateTime']
  }

export type CatalogInput = {
  productIds?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type CatalogResponse = Res & {
  __typename?: 'CatalogResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Catalog>
  /** The total number of items */
  total: Scalars['Int']
}

export type Category = Entry &
  Record & {
    __typename?: 'Category'
    channel?: Maybe<Channel>
    content: Array<MediaItem>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    id: Scalars['ID']
    name: Scalars['String']
    publishOn?: Maybe<Scalars['DateTime']>
    status: EntryStatus
    tags: Array<Maybe<Tag>>
    updatedAt: Scalars['DateTime']
  }

export type CategoryConnectInput = {
  id?: InputMaybe<Scalars['ID']>
}

export type CategoryInput = {
  gates?: InputMaybe<Array<GateType>>
  productIds?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type CategoryResponse = Res & {
  __typename?: 'CategoryResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Category>
  /** The total number of items */
  total: Scalars['Int']
}

export type Channel = Record & {
  __typename?: 'Channel'
  category?: Maybe<Category>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  logo?: Maybe<Image>
  name: Scalars['String']
  originId?: Maybe<Scalars['String']>
  sixteenNineCover?: Maybe<Image>
  tallBackdrop?: Maybe<Image>
  twoThreeCover?: Maybe<Image>
  updatedAt: Scalars['DateTime']
  wideBackdrop?: Maybe<Image>
}

export type ChannelInput = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<ChannelStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type ChannelResponse = Res & {
  __typename?: 'ChannelResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Channel>
  /** The total number of items */
  total: Scalars['Int']
}

export enum ChannelStatus {
  Assigned = 'assigned',
  Excluded = 'excluded',
  Unassigned = 'unassigned',
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession'
  url?: Maybe<Scalars['String']>
}

export enum ComparisonOperator {
  Eq = 'eq',
  Neq = 'neq',
}

export type ConfirmedSetupIntentInput = {
  primary?: InputMaybe<Scalars['Boolean']>
  source: Scalars['String']
}

export type ConnectDomainInput = {
  domain: Scalars['String']
  tenantId?: InputMaybe<Scalars['String']>
}

export enum ContactSalesContentType {
  Education = 'EDUCATION',
  Entertainment = 'ENTERTAINMENT',
  Faith = 'FAITH',
  Fitness = 'FITNESS',
  Instructional = 'INSTRUCTIONAL',
  Kids = 'KIDS',
  News = 'NEWS',
  Other = 'OTHER',
  PerformingArts = 'PERFORMING_ARTS',
  School = 'SCHOOL',
  Sports = 'SPORTS',
}

export type ContactSalesInput = {
  channelUrl?: InputMaybe<Scalars['String']>
  companyName: Scalars['String']
  contentType: ContactSalesContentType
  email: Scalars['String']
  message?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  numberOfSubscribers: ContactSalesNumberOfSubscribers
  phone: Scalars['String']
  product?: InputMaybe<ContactSalesProduct>
  role: ContactSalesRole
  type: ContactSalesType
}

export enum ContactSalesNumberOfSubscribers {
  S_500 = 'S_500',
  S_501_1000 = 'S_501_1000',
  S_1001_1500 = 'S_1001_1500',
  S_1501_2000 = 'S_1501_2000',
  S_2501_3000 = 'S_2501_3000',
  S_3001_3500 = 'S_3001_3500',
  S_3501_4000 = 'S_3501_4000',
  S_4001_4500 = 'S_4001_4500',
  S_4501_5000 = 'S_4501_5000',
  S_5000 = 'S_5000',
}

export enum ContactSalesProduct {
  Enterprise = 'ENTERPRISE',
  Growth = 'GROWTH',
  Pro = 'PRO',
  Starter = 'STARTER',
}

export enum ContactSalesRole {
  Administration = 'ADMINISTRATION',
  BusinessDevelopment = 'BUSINESS_DEVELOPMENT',
  BusinessOwner = 'BUSINESS_OWNER',
  CommunityAndSocialServices = 'COMMUNITY_AND_SOCIAL_SERVICES',
  CreativeDirectorArtDirector = 'CREATIVE_DIRECTOR_ART_DIRECTOR',
  CustomerSupport = 'CUSTOMER_SUPPORT',
  EducatorTeacher = 'EDUCATOR_TEACHER',
  Engineering = 'ENGINEERING',
  ExecutiveLeadershipCLevel = 'EXECUTIVE_LEADERSHIP_C_LEVEL',
  FilmmakerFullTime = 'FILMMAKER_FULL_TIME',
  FilmmakerHobbyist = 'FILMMAKER_HOBBYIST',
  FinanceAccounting = 'FINANCE_ACCOUNTING',
  HumanResourcesPeople = 'HUMAN_RESOURCES_PEOPLE',
  InformationTechnology = 'INFORMATION_TECHNOLOGY',
  Legal = 'LEGAL',
  Marketing = 'MARKETING',
  Nonprofit = 'NONPROFIT',
  Operations = 'OPERATIONS',
  OtherBusinessProfessionalOrganizationalRole = 'OTHER_BUSINESS_PROFESSIONAL_ORGANIZATIONAL_ROLE',
  OtherNonbusinessPersonalUser = 'OTHER_NONBUSINESS_PERSONAL_USER',
  ProjectProductionManagement = 'PROJECT_PRODUCTION_MANAGEMENT',
  ReligiousSpiritualOrFaithBasedLender = 'RELIGIOUS_SPIRITUAL_OR_FAITH_BASED_LENDER',
  Sales = 'SALES',
  Student = 'STUDENT',
}

export enum ContactSalesType {
  Contact = 'CONTACT',
  DemoRequest = 'DEMO_REQUEST',
}

export type Content = {
  appliedGates: Array<Gate>
  bundles: Array<Bundle>
  categories: Array<Category>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  downloadUrl?: Maybe<Scalars['String']>
  downloadsEnabled: Scalars['Boolean']
  /** Duration in seconds */
  duration?: Maybe<Scalars['Int']>
  enabled: Scalars['Boolean']
  expireOn?: Maybe<Scalars['DateTime']>
  favorited?: Maybe<Scalars['Boolean']>
  featured: Scalars['Boolean']
  featuredOrder?: Maybe<Scalars['Int']>
  fileName?: Maybe<Scalars['String']>
  gate?: Maybe<Gate>
  gateEnabled: Scalars['Boolean']
  id: Scalars['ID']
  logo?: Maybe<Image>
  metadata: Array<Metadata>
  name: Scalars['String']
  originId?: Maybe<Scalars['String']>
  publishOn?: Maybe<Scalars['DateTime']>
  purchased?: Maybe<Scalars['Boolean']>
  restrictions?: Maybe<Array<ContentRestrictionsType>>
  sixteenNineCover?: Maybe<Image>
  srcVideo?: Maybe<Scalars['String']>
  status: EntryStatus
  subtitle?: Maybe<Scalars['String']>
  tags: Array<Maybe<Tag>>
  tallBackdrop?: Maybe<Image>
  twoThreeCover?: Maybe<Image>
  type: ContentType
  updatedAt: Scalars['DateTime']
  /** The last view time and progress of the content by the user if authenticated */
  viewer?: Maybe<Viewer>
  wideBackdrop?: Maybe<Image>
  workflowId?: Maybe<Scalars['String']>
  workflowStatus: WorkflowStatus
}

export type ContentInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  gates?: InputMaybe<Array<GateType>>
  productIds?: InputMaybe<Array<Scalars['String']>>
  schedule?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

export type ContentResponse = Res & {
  __typename?: 'ContentResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Maybe<Content>>
  /** The total number of items */
  total: Scalars['Int']
}

export enum ContentRestrictionsType {
  Bundle = 'bundle',
  Ppv = 'ppv',
  Registration = 'registration',
  Subscription = 'subscription',
}

export enum ContentType {
  Event = 'event',
  Series = 'series',
  Video = 'video',
}

export type CreateCheckoutSessionInput = {
  bundleId?: InputMaybe<Scalars['String']>
  contentId?: InputMaybe<Scalars['String']>
  priceId: Scalars['String']
  redirectUrl: Scalars['String']
}

export type CreateSetupIntentInput = {
  paymentMethodId?: InputMaybe<Scalars['String']>
}

export type CreateUserInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  /** The hostname to use for the confirmation link when sending the email */
  hostname?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  password?: InputMaybe<Scalars['String']>
  /** The port to use for the confirmation link when sending the email */
  port?: InputMaybe<Scalars['String']>
  /** The protocol to use for the confirmation link when sending the email. Defaults to https */
  protocol?: InputMaybe<Scalars['String']>
  /** The redirect URL to use for the confirmation link when sending the email */
  redirectUrl?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<InputMaybe<Role>>>
  /** Used when creating a user from the customer portal to determine whether the user should set their password in the customer portal or the OTT app */
  source?: InputMaybe<RequestSource>
}

export enum Currency {
  Aud = 'aud',
  Brl = 'brl',
  Cad = 'cad',
  Chf = 'chf',
  Cny = 'cny',
  Eur = 'eur',
  Gbp = 'gbp',
  Hkd = 'hkd',
  Inr = 'inr',
  Jpy = 'jpy',
  Krw = 'krw',
  Mxn = 'mxn',
  Nok = 'nok',
  Nzd = 'nzd',
  Rub = 'rub',
  Sek = 'sek',
  Sgd = 'sgd',
  Try = 'try',
  Twd = 'twd',
  Usd = 'usd',
  Zar = 'zar',
}

export type Customer = {
  __typename?: 'Customer'
  description?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  livemode: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  subscriptions: Array<Subscription>
}

export type DnsRecord = {
  __typename?: 'DNSRecord'
  name: Scalars['String']
  type: DnsRecordType
  value: Scalars['String']
}

export enum DnsRecordType {
  A = 'A',
  Cname = 'CNAME',
}

export type DeleteAccountInput = {
  id: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}

export type DeleteAccountResponse = {
  __typename?: 'DeleteAccountResponse'
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Domain = Record & {
  __typename?: 'Domain'
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  domain: Scalars['String']
  host: Scalars['String']
  id: Scalars['ID']
  status: DomainStatus
  subdomain?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  validationRecords: Array<DnsRecord>
}

export enum DomainStatus {
  Activating = 'activating',
  Active = 'active',
  Failed = 'failed',
  Unverified = 'unverified',
  Verified = 'verified',
  Verifying = 'verifying',
}

export type Entry = {
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  downloadsEnabled: Scalars['Boolean']
  enabled: Scalars['Boolean']
  expireOn?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  name: Scalars['String']
  publishOn?: Maybe<Scalars['DateTime']>
  status: EntryStatus
  tags: Array<Maybe<Tag>>
  updatedAt: Scalars['DateTime']
}

export enum EntryStatus {
  Disabled = 'disabled',
  Expired = 'expired',
  Published = 'published',
  Unpublished = 'unpublished',
}

export type Environment = Record & {
  __typename?: 'Environment'
  accountId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  customDomains: Array<Domain>
  domain: Domain
  exception?: Maybe<EnvironmentExceptionType>
  frontendStack?: Maybe<FrontendStack>
  id: Scalars['ID']
  message?: Maybe<Scalars['String']>
  name: Scalars['String']
  status: EnvironmentStatus
  updatedAt: Scalars['DateTime']
  vodStack?: Maybe<VodStack>
}

export enum EnvironmentExceptionType {
  Certificate = 'certificate',
  CreateAccount = 'create_account',
  CreateResources = 'create_resources',
  Deploy = 'deploy',
  HostedZone = 'hosted_zone',
  PrepareResources = 'prepare_resources',
  Unknown = 'unknown',
}

export enum EnvironmentStatus {
  Active = 'active',
  CreatingAccount = 'creating_account',
  CreatingResources = 'creating_resources',
  Deploying = 'deploying',
  Failed = 'failed',
  Inactive = 'inactive',
  Pending = 'pending',
  PreparingResources = 'preparing_resources',
}

export type ErrorContentInput = {
  id: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
}

export type Event = Content &
  Entry &
  Record & {
    __typename?: 'Event'
    appliedGates: Array<Gate>
    bundles: Array<Bundle>
    categories: Array<Category>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadUrl?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    /** Duration in seconds */
    duration?: Maybe<Scalars['Int']>
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    favorited?: Maybe<Scalars['Boolean']>
    featured: Scalars['Boolean']
    featuredOrder?: Maybe<Scalars['Int']>
    fileName?: Maybe<Scalars['String']>
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    id: Scalars['ID']
    liveNow: Scalars['Boolean']
    logo?: Maybe<Image>
    metadata: Array<Metadata>
    name: Scalars['String']
    originId?: Maybe<Scalars['String']>
    publishOn?: Maybe<Scalars['DateTime']>
    purchased?: Maybe<Scalars['Boolean']>
    restrictions?: Maybe<Array<ContentRestrictionsType>>
    scheduledEnd?: Maybe<Scalars['DateTime']>
    scheduledStart?: Maybe<Scalars['DateTime']>
    sixteenNineCover?: Maybe<Image>
    srcVideo?: Maybe<Scalars['String']>
    status: EntryStatus
    subtitle?: Maybe<Scalars['String']>
    tags: Array<Maybe<Tag>>
    tallBackdrop?: Maybe<Image>
    twoThreeCover?: Maybe<Image>
    type: ContentType
    updatedAt: Scalars['DateTime']
    /** The last view time and progress of the content by the user if authenticated */
    viewer?: Maybe<Viewer>
    wideBackdrop?: Maybe<Image>
    workflowId?: Maybe<Scalars['String']>
    workflowStatus: WorkflowStatus
  }

export type EventResponse = Res & {
  __typename?: 'EventResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Event>
  /** The total number of items */
  total: Scalars['Int']
}

export type ExcludeUnassignChannelInput = {
  ids: Array<Scalars['String']>
}

/** The data for rendering an explore page */
export type Explore = {
  __typename?: 'Explore'
  /** A list of media rows to be displayed on the explore page */
  rows: Array<Maybe<MediaRow>>
}

/** The data for rendering an explore page */
export type ExploreRowsArgs = {
  data?: InputMaybe<ExploreInput>
}

export type ExploreInput = {
  search?: InputMaybe<Scalars['String']>
}

/** A container type that can be either a video or event to be used for handling featured content in the customer portal */
export type Featured = Event | Video

/** A response type that contains a list of featured content */
export type FeaturedResponse = {
  __typename?: 'FeaturedResponse'
  list: Array<Maybe<Featured>>
}

export type FilterGroup = Record & {
  __typename?: 'FilterGroup'
  createdAt: Scalars['DateTime']
  groups?: Maybe<Array<FilterGroup>>
  id: Scalars['ID']
  operator: LogicalOperator
  rules?: Maybe<Array<FilterRule>>
  updatedAt: Scalars['DateTime']
}

export type FilterGroupInput = {
  rules?: InputMaybe<Array<FilterRuleInput>>
}

export enum FilterProperty {
  Category = 'category',
}

export type FilterRule = {
  __typename?: 'FilterRule'
  categoryValue?: Maybe<Category>
  operator: ComparisonOperator
  property: FilterProperty
}

export type FilterRuleInput = {
  categoryValue?: InputMaybe<CategoryConnectInput>
}

export type Footer = {
  __typename?: 'Footer'
  copyright: Scalars['String']
  description?: Maybe<Scalars['String']>
  links: Array<Maybe<Link>>
}

export type ForgotPasswordInput = {
  email: Scalars['String']
  hostname: Scalars['String']
  port?: InputMaybe<Scalars['String']>
  protocol?: InputMaybe<Scalars['String']>
  redirectUrl?: InputMaybe<Scalars['String']>
  source: RequestSource
}

export type FrontendStack = Record &
  Stack & {
    __typename?: 'FrontendStack'
    buildBucket: Scalars['String']
    createdAt: Scalars['DateTime']
    customerPortalBucket: Scalars['String']
    id: Scalars['ID']
    name?: Maybe<Scalars['String']>
    ottBucket: Scalars['String']
    updatedAt: Scalars['DateTime']
    version?: Maybe<Scalars['String']>
  }

export type Gate = Record & {
  __typename?: 'Gate'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  product?: Maybe<Product>
  type: GateType
  updatedAt: Scalars['DateTime']
}

export enum GateType {
  Ppv = 'ppv',
  Registration = 'registration',
  Subscription = 'subscription',
}

/** The data for rendering the header and footer of a site */
export type Global = {
  __typename?: 'Global'
  footer: Footer
  header: Header
  liveUpcoming: Scalars['Boolean']
}

export type Header = {
  __typename?: 'Header'
  brand: Link
  links: Array<Link>
  more: Array<Link>
}

/** The data for rendering a home page */
export type Home = {
  __typename?: 'Home'
  /** A list of media items to be featured in the carousel at the top of the page */
  featured: Array<Maybe<MediaItem>>
  /** A list of media rows to be rendered on the page in descending order */
  rows: Array<MediaRow>
}

/** The data for rendering a home page */
export type HomeRowsArgs = {
  data?: InputMaybe<MediaRowInput>
}

export enum IapInterval {
  Month = 'month',
  Year = 'year',
}

export enum IapPlatform {
  Android = 'android',
  Ios = 'ios',
}

export type Image = {
  __typename?: 'Image'
  height?: Maybe<Scalars['Int']>
  set?: Maybe<Array<Image>>
  src: Scalars['String']
  width?: Maybe<Scalars['Int']>
}

export type ImageInput = {
  set?: InputMaybe<Array<ImageInput>>
  src: Scalars['String']
}

export type IngestMessageInput = {
  message: Scalars['String']
  origin: MessageOrigin
  type: MessageType
}

export type InstallIntegrationInput = {
  id: Scalars['String']
}

export type Integration = {
  __typename?: 'Integration'
  author?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  installed: Scalars['Boolean']
  license?: Maybe<Scalars['String']>
  logo?: Maybe<Image>
  metadata?: Maybe<Array<Metadata>>
  name: Scalars['String']
  path?: Maybe<Scalars['String']>
  published: Scalars['Boolean']
  shortDescription?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  supportUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  version: Scalars['String']
  windowFeatures?: Maybe<Scalars['String']>
}

export type IntegrationDetail = {
  __typename?: 'IntegrationDetail'
  authUri: Scalars['String']
  authorized: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  enabled: Scalars['Boolean']
  id: Scalars['String']
  integration: Integration
  updatedAt: Scalars['DateTime']
}

export type IntegrationDetailInput = {
  enabled?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type IntegrationDetailResponse = Res & {
  __typename?: 'IntegrationDetailResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<IntegrationDetail>
  /** The total number of items */
  total: Scalars['Int']
}

export type IntegrationInput = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type IntegrationResponse = Res & {
  __typename?: 'IntegrationResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Integration>
  /** The total number of items */
  total: Scalars['Int']
}

export enum Interval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

/** A type that represents a customer's invoice */
export type Invoice = {
  __typename?: 'Invoice'
  id?: Maybe<Scalars['String']>
}

export type Link = Record & {
  __typename?: 'Link'
  createdAt: Scalars['DateTime']
  custom?: Maybe<Scalars['Boolean']>
  icon?: Maybe<Image>
  id: Scalars['ID']
  order?: Maybe<Scalars['Int']>
  route?: Maybe<Route>
  text: Scalars['String']
  type: LinkType
  updatedAt: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
}

export type LinkResponse = Res & {
  __typename?: 'LinkResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Link>
  /** The total number of items */
  total: Scalars['Int']
}

export enum LinkType {
  External = 'external',
  Internal = 'internal',
}

export type Links = {
  __typename?: 'Links'
  links: Array<Link>
}

export enum LogicalOperator {
  And = 'and',
  Or = 'or',
}

export type LoginInput = {
  email: Scalars['String']
  password: Scalars['String']
}

/** A container type that can be either a video or event and rendered within a media row */
export type MediaItem = Event | Video

export type MediaItemInput = {
  /** The catalog ID tied to this media row */
  id?: InputMaybe<Scalars['ID']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

/** A container type that can be either a video or event and rendered within a media row */
export type MediaItemResponse = Res & {
  __typename?: 'MediaItemResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  /** A list of media items */
  list: Array<MediaItem>
  /** The total number of items */
  total: Scalars['Int']
}

/** A type that represents a row of media items to be rendered on a page */
export type MediaRow = {
  __typename?: 'MediaRow'
  catalogId?: Maybe<Scalars['String']>
  /** An optional icon to be displayed left of the title */
  icon?: Maybe<Image>
  id: Scalars['ID']
  /** A paginated list of media items to be rendered in this row */
  items: MediaItemResponse
  title: Scalars['String']
  type: MediaRowType
}

/** A type that represents a row of media items to be rendered on a page */
export type MediaRowItemsArgs = {
  data?: InputMaybe<MediaItemInput>
}

export type MediaRowInput = {
  /** The catalog ID tied to this media row */
  catalogId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

/** Live media rows are rendered slightly differently than standard media rows */
export enum MediaRowType {
  AvailableForYou = 'availableForYou',
  AvailableOnDemand = 'availableOnDemand',
  Catalog = 'catalog',
  Favorites = 'favorites',
  IncludedWithSubscription = 'includedWithSubscription',
  KeepWatching = 'keepWatching',
  LiveAndUpcomingEvents = 'liveAndUpcomingEvents',
  New = 'new',
  PastEvents = 'pastEvents',
  Purchases = 'purchases',
  Recent = 'recent',
  RecentlyWatched = 'recentlyWatched',
  RegisterToWatch = 'registerToWatch',
  TopResults = 'topResults',
  Upcoming = 'upcoming',
  WatchHistory = 'watchHistory',
}

export enum MessageOrigin {
  Sns = 'sns',
  Sqs = 'sqs',
}

/** The message response for simple mutations such as forgot password or reset password */
export type MessageResponse = {
  __typename?: 'MessageResponse'
  message: Scalars['String']
}

export enum MessageType {
  Vod = 'vod',
}

export type Metadata = {
  __typename?: 'Metadata'
  name: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  activateDomain: Domain
  addPageToMenu?: Maybe<MessageResponse>
  addToFeatured?: Maybe<MessageResponse>
  addToWatchlist?: Maybe<MessageResponse>
  archiveProducts?: Maybe<MessageResponse>
  assignChannel?: Maybe<Channel>
  authorizeIntegrationDetail?: Maybe<IntegrationDetail>
  confirmedTenantSetupIntent?: Maybe<MessageResponse>
  connectDomain: Domain
  contactSales?: Maybe<MessageResponse>
  createCheckoutSession?: Maybe<CheckoutSession>
  createPortalSession?: Maybe<PortalSession>
  createTenantSetupIntent?: Maybe<SetupIntent>
  createUser?: Maybe<User>
  deleteAccount?: Maybe<DeleteAccountResponse>
  deleteCatalogs?: Maybe<MessageResponse>
  deleteCategories?: Maybe<MessageResponse>
  deleteIntegrationDetails?: Maybe<MessageResponse>
  deleteLinks?: Maybe<MessageResponse>
  deletePages?: Maybe<MessageResponse>
  deleteRows?: Maybe<MessageResponse>
  deleteUsers?: Maybe<MessageResponse>
  deleteVideos?: Maybe<MessageResponse>
  errorContent?: Maybe<MessageResponse>
  excludeChannel?: Maybe<MessageResponse>
  forgotPassword?: Maybe<MessageResponse>
  ingestMessage?: Maybe<Content>
  login?: Maybe<User>
  logout?: Maybe<Scalars['Boolean']>
  recordIap?: Maybe<MessageResponse>
  registerTenant?: Maybe<RegisterTenant>
  removeDomain: MessageResponse
  removeFromFeatured?: Maybe<MessageResponse>
  removeFromWatchlist?: Maybe<MessageResponse>
  removePagesFromMenu?: Maybe<MessageResponse>
  removeTenantPaymentMethod?: Maybe<MessageResponse>
  reorderFeaturedContent?: Maybe<MessageResponse>
  report?: Maybe<Report>
  resetPassword?: Maybe<MessageResponse>
  unassignChannel?: Maybe<MessageResponse>
  updateEnvironment?: Maybe<Environment>
  updateMe?: Maybe<User>
  updatePassword?: Maybe<MessageResponse>
  updateTenant?: Maybe<Tenant>
  updateTenantPaymentMethod?: Maybe<Card>
  updateUser?: Maybe<User>
  upsertCatalog?: Maybe<Catalog>
  upsertCategory?: Maybe<Category>
  upsertEvent?: Maybe<Event>
  upsertIntegrationDetail?: Maybe<IntegrationDetail>
  upsertLink?: Maybe<Link>
  upsertLinks?: Maybe<Links>
  upsertPage?: Maybe<Page>
  upsertPages?: Maybe<Pages>
  upsertProduct?: Maybe<Product>
  upsertRepository: Repository
  upsertRow?: Maybe<Row>
  upsertRows?: Maybe<Rows>
  upsertVideo?: Maybe<Video>
  verifyCheckoutSession?: Maybe<MessageResponse>
}

export type MutationActivateDomainArgs = {
  data: ActivateDomainInput
}

export type MutationAddPageToMenuArgs = {
  data: AddPageToMenuInput
}

export type MutationAddToFeaturedArgs = {
  data: RecordByIdInput
}

export type MutationAddToWatchlistArgs = {
  data: UpdateWatchlistInput
}

export type MutationArchiveProductsArgs = {
  data: ArchiveProductsInput
}

export type MutationAssignChannelArgs = {
  data: AssignChannelInput
}

export type MutationAuthorizeIntegrationDetailArgs = {
  data: AuthorizeIntegrationDetailInput
}

export type MutationConfirmedTenantSetupIntentArgs = {
  data: ConfirmedSetupIntentInput
}

export type MutationConnectDomainArgs = {
  data: ConnectDomainInput
}

export type MutationContactSalesArgs = {
  data: ContactSalesInput
}

export type MutationCreateCheckoutSessionArgs = {
  data: CreateCheckoutSessionInput
}

export type MutationCreatePortalSessionArgs = {
  data: PortalSessionInput
}

export type MutationCreateTenantSetupIntentArgs = {
  data: CreateSetupIntentInput
}

export type MutationCreateUserArgs = {
  data: CreateUserInput
}

export type MutationDeleteAccountArgs = {
  data: DeleteAccountInput
}

export type MutationDeleteCatalogsArgs = {
  data: RecordsByIdsInput
}

export type MutationDeleteCategoriesArgs = {
  data: RecordsByIdsInput
}

export type MutationDeleteIntegrationDetailsArgs = {
  data: RecordsByIdsInput
}

export type MutationDeleteLinksArgs = {
  data: RecordsByIdsInput
}

export type MutationDeletePagesArgs = {
  data: RecordsByIdsInput
}

export type MutationDeleteRowsArgs = {
  data: RecordsByIdsInput
}

export type MutationDeleteUsersArgs = {
  data: RecordsByIdsInput
}

export type MutationDeleteVideosArgs = {
  data: RecordsByIdsInput
}

export type MutationErrorContentArgs = {
  data: ErrorContentInput
}

export type MutationExcludeChannelArgs = {
  data: ExcludeUnassignChannelInput
}

export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput
}

export type MutationIngestMessageArgs = {
  data: IngestMessageInput
}

export type MutationLoginArgs = {
  data: LoginInput
}

export type MutationRecordIapArgs = {
  data: RecordIapInput
}

export type MutationRegisterTenantArgs = {
  data: RegisterTenantInput
}

export type MutationRemoveDomainArgs = {
  data: RemoveDomainInput
}

export type MutationRemoveFromFeaturedArgs = {
  data: RecordByIdInput
}

export type MutationRemoveFromWatchlistArgs = {
  data: UpdateWatchlistInput
}

export type MutationRemovePagesFromMenuArgs = {
  data: RecordsByIdsInput
}

export type MutationRemoveTenantPaymentMethodArgs = {
  data: RemovePaymentMethodInput
}

export type MutationReorderFeaturedContentArgs = {
  data: ReorderFeaturedInput
}

export type MutationReportArgs = {
  data: ReportInput
}

export type MutationResetPasswordArgs = {
  data: ResetPasswordInput
}

export type MutationUnassignChannelArgs = {
  data: ExcludeUnassignChannelInput
}

export type MutationUpdateEnvironmentArgs = {
  data: UpdateEnvironmentInput
}

export type MutationUpdateMeArgs = {
  data: UpdateMeInput
}

export type MutationUpdatePasswordArgs = {
  data: UpdatePasswordInput
}

export type MutationUpdateTenantArgs = {
  data: UpdateTenantInput
}

export type MutationUpdateTenantPaymentMethodArgs = {
  data: UpdatePaymentMethodInput
}

export type MutationUpdateUserArgs = {
  data: UpdateUserInput
}

export type MutationUpsertCatalogArgs = {
  data: UpsertCatalogInput
}

export type MutationUpsertCategoryArgs = {
  data: UpsertCategoryInput
}

export type MutationUpsertEventArgs = {
  data: UpsertEventInput
}

export type MutationUpsertIntegrationDetailArgs = {
  data: UpsertIntegrationDetailInput
}

export type MutationUpsertLinkArgs = {
  data: UpsertLinkInput
}

export type MutationUpsertLinksArgs = {
  data: UpsertLinksInput
}

export type MutationUpsertPageArgs = {
  data: UpsertPageInput
}

export type MutationUpsertPagesArgs = {
  data: UpsertPagesInput
}

export type MutationUpsertProductArgs = {
  data: UpsertProductInput
}

export type MutationUpsertRepositoryArgs = {
  data: UpsertRepositoryInput
}

export type MutationUpsertRowArgs = {
  data: UpsertRowInput
}

export type MutationUpsertRowsArgs = {
  data: UpsertRowsInput
}

export type MutationUpsertVideoArgs = {
  data: UpsertVideoInput
}

export type MutationVerifyCheckoutSessionArgs = {
  data: VerifyCheckoutSessionInput
}

/** The data for rendering the my library page */
export type MyLibrary = {
  __typename?: 'MyLibrary'
  /** A list of media rows to be displayed on the my library page */
  rows: Array<Maybe<MediaRow>>
}

export type NotionApiConfig = {
  __typename?: 'NotionApiConfig'
  authorizationSecret: Scalars['String']
  databases: RegisterNotionDatabases
}

export type NotionApiConfigInput = {
  authorizationSecret: Scalars['String']
  databases: RegisterNotionDatabasesInput
}

export type Page = Entry &
  Record & {
    __typename?: 'Page'
    bannerImage?: Maybe<Image>
    /** Describes how to filter the content for the catalog. */
    contentFilter?: Maybe<FilterGroup>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    featured: Scalars['Boolean']
    /** The icon for the catalog. May be rendered in its media row titles. */
    icon?: Maybe<Image>
    id: Scalars['ID']
    logo?: Maybe<Image>
    /** The menu that the page is in. */
    menu: PageMenu
    name: Scalars['String']
    order: Scalars['Int']
    publishOn?: Maybe<Scalars['DateTime']>
    rows: Array<MediaRow>
    slug: Scalars['String']
    status: EntryStatus
    tags: Array<Maybe<Tag>>
    updatedAt: Scalars['DateTime']
  }

export type PageInput = {
  gates?: InputMaybe<Array<GateType>>
  menu?: InputMaybe<PageMenu>
  productIds?: InputMaybe<Array<Scalars['String']>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<EntryStatus>
  take?: InputMaybe<Scalars['Int']>
}

/** The different menus that pages can be in. */
export enum PageMenu {
  Header = 'header',
  Hidden = 'hidden',
  More = 'more',
}

export type PageResponse = Res & {
  __typename?: 'PageResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Page>
  /** The total number of items */
  total: Scalars['Int']
}

export type Pages = {
  __typename?: 'Pages'
  pages: Array<Page>
}

/** A type that represents a customer's payment method */
export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  type: PaymentMethodType
}

export enum PaymentMethodType {
  Card = 'card',
}

export type PaymentMethods = {
  __typename?: 'PaymentMethods'
  cards: Array<Card>
  others: Array<PaymentMethod>
}

export type Platform = {
  __typename?: 'Platform'
  description: Scalars['String']
  layout?: Maybe<Scalars['String']>
  manufacturer?: Maybe<Scalars['String']>
  name: Scalars['String']
  os: Scalars['String']
  product?: Maybe<Scalars['String']>
  version: Scalars['String']
}

export type Player = {
  __typename?: 'Player'
  contentId: Scalars['String']
  cookies: Array<PlayerSignedCookie>
  /** The user's current resume position, if any, in seconds */
  position?: Maybe<Scalars['Int']>
  /** The player's signed hls url */
  signedUrl: Scalars['String']
  /** The player's hls url */
  url: Scalars['String']
}

export type PlayerEvent = {
  __typename?: 'PlayerEvent'
  duration: Scalars['Int']
  durationBuffering: Scalars['Int']
  host: Scalars['String']
  platform: Platform
  playerVersion: Scalars['String']
  position: Scalars['Float']
  sessionId: Scalars['String']
  timestamp: Scalars['DateTime']
  type: PlayerEventType
  viewerId: Scalars['String']
}

export enum PlayerEventType {
  Buffer = 'buffer',
  Complete = 'complete',
  Error = 'error',
  Pause = 'pause',
  Play = 'play',
  Seek = 'seek',
  Time = 'time',
}

export type PlayerInput = {
  /** The ID of the content to play */
  contentId: Scalars['ID']
}

export type PlayerSignedCookie = {
  __typename?: 'PlayerSignedCookie'
  key: Scalars['String']
  value: Scalars['String']
}

export type PortalSession = {
  __typename?: 'PortalSession'
  url?: Maybe<Scalars['String']>
}

export type PortalSessionInput = {
  returnUrl: Scalars['String']
}

export type Price = StripeObject & {
  __typename?: 'Price'
  active: Scalars['Boolean']
  amount: Scalars['Int']
  createdAt: Scalars['DateTime']
  currency: Currency
  iapSkuAndroid?: Maybe<Scalars['String']>
  iapSkuIos?: Maybe<Scalars['String']>
  id: Scalars['ID']
  livemode: Scalars['Boolean']
  metadata: Array<Metadata>
  object: StripeObjectType
  product: Scalars['String']
  recurring?: Maybe<Recurring>
  taxBehavior: TaxBehavior
  type: PriceType
  updatedAt: Scalars['DateTime']
}

export enum PriceType {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

export type Product = StripeObject & {
  __typename?: 'Product'
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  defaultPrice?: Maybe<Price>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  images: Array<Image>
  livemode: Scalars['Boolean']
  metadata: Array<Metadata>
  name: Scalars['String']
  object: StripeObjectType
  originId: Scalars['String']
  prices: Array<Price>
  unitLabel?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  url?: Maybe<Scalars['String']>
}

export type ProductInput = {
  active?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
}

export type ProductResponse =
  | ProductResponseErrorSetupInProgress
  | ProductResponseErrorSetupNotStarted
  | ProductResponseErrorSetupPending
  | ProductResponseSuccess

export type ProductResponseErrorSetupInProgress = {
  __typename?: 'ProductResponseErrorSetupInProgress'
  message: Scalars['String']
}

export type ProductResponseErrorSetupNotStarted = {
  __typename?: 'ProductResponseErrorSetupNotStarted'
  message: Scalars['String']
}

export type ProductResponseErrorSetupPending = {
  __typename?: 'ProductResponseErrorSetupPending'
  message: Scalars['String']
}

export type ProductResponseSuccess = Res & {
  __typename?: 'ProductResponseSuccess'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Product>
  /** The total number of items */
  total: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  /** Returns the data for rendering a media row */
  all?: Maybe<All>
  awsCredentials?: Maybe<AwsCredentials>
  catalog?: Maybe<Catalog>
  catalogs?: Maybe<CatalogResponse>
  categories?: Maybe<CategoryResponse>
  category?: Maybe<Category>
  channel?: Maybe<Channel>
  channels?: Maybe<ChannelResponse>
  event?: Maybe<Event>
  events?: Maybe<EventResponse>
  /** Returns the data for rendering a tenant's explore page */
  explore?: Maybe<Explore>
  featured?: Maybe<FeaturedResponse>
  /** Returns the data for rendering a tenant's header and footer */
  global?: Maybe<Global>
  /** Returns the data for rendering a tenant's home page */
  home?: Maybe<Home>
  integration?: Maybe<Integration>
  integrationDetails?: Maybe<IntegrationDetailResponse>
  integrations?: Maybe<IntegrationResponse>
  link?: Maybe<Link>
  links: Array<Link>
  liveNow?: Maybe<Array<Event>>
  me?: Maybe<User>
  /** Returns the details for a media item */
  mediaItem?: Maybe<MediaItem>
  mediaRow?: Maybe<MediaRow>
  /** Returns the data for rendering a tenant's my library page */
  myLibrary?: Maybe<MyLibrary>
  page?: Maybe<Page>
  pages?: Maybe<PageResponse>
  /** Returns the data for a content's video player such as the source url */
  player?: Maybe<Player>
  product?: Maybe<Product>
  products?: Maybe<ProductResponse>
  repositories: Array<Repository>
  repositoryAuth?: Maybe<RepositoryAuth>
  row?: Maybe<Row>
  rows?: Maybe<RowResponse>
  stripeAccountLink: StripeAccountLink
  tenant?: Maybe<Tenant>
  /** Returns the billing information for a tenant's organization. */
  tenantBilling?: Maybe<Billing>
  tenants: Array<Tenant>
  users?: Maybe<UserResponse>
  video?: Maybe<Video>
  videos?: Maybe<VideoResponse>
}

export type QueryAwsCredentialsArgs = {
  data: AwsCredentialsInput
}

export type QueryCatalogArgs = {
  data: RecordByIdInput
}

export type QueryCatalogsArgs = {
  data: CatalogInput
}

export type QueryCategoriesArgs = {
  data: CategoryInput
}

export type QueryCategoryArgs = {
  data: RecordByIdInput
}

export type QueryChannelArgs = {
  data: RecordByIdInput
}

export type QueryChannelsArgs = {
  data: ChannelInput
}

export type QueryEventArgs = {
  data: RecordByIdInput
}

export type QueryEventsArgs = {
  data: ContentInput
}

export type QueryIntegrationArgs = {
  data: RecordByIdInput
}

export type QueryIntegrationDetailsArgs = {
  data?: InputMaybe<IntegrationDetailInput>
}

export type QueryIntegrationsArgs = {
  data?: InputMaybe<IntegrationInput>
}

export type QueryLinkArgs = {
  data: RecordByIdInput
}

export type QueryMediaItemArgs = {
  data: RecordByIdInput
}

export type QueryMediaRowArgs = {
  data: MediaRowInput
}

export type QueryPageArgs = {
  data: SinglePageInput
}

export type QueryPagesArgs = {
  data: PageInput
}

export type QueryPlayerArgs = {
  data: PlayerInput
}

export type QueryProductArgs = {
  data: RecordByIdInput
}

export type QueryProductsArgs = {
  data: ProductInput
}

export type QueryRepositoriesArgs = {
  data: RepositoriesInput
}

export type QueryRepositoryAuthArgs = {
  data: RepositoryAuthInput
}

export type QueryRowArgs = {
  data: RecordByIdInput
}

export type QueryRowsArgs = {
  data: RowInput
}

export type QueryStripeAccountLinkArgs = {
  data: StripeAccountLinkInput
}

export type QueryUsersArgs = {
  data: UserInput
}

export type QueryVideoArgs = {
  data: RecordByIdInput
}

export type QueryVideosArgs = {
  data: ContentInput
}

export type Record = {
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  updatedAt: Scalars['DateTime']
}

export type RecordByIdInput = {
  id: Scalars['String']
}

export type RecordIapInput = {
  contentId?: InputMaybe<Scalars['String']>
  interval?: InputMaybe<IapInterval>
  platform: IapPlatform
  recurring: Scalars['Boolean']
  sku: Scalars['String']
}

export type RecordsByIdsInput = {
  ids: Array<Scalars['String']>
}

export type Recurring = {
  __typename?: 'Recurring'
  interval: Interval
  intervalCount: Scalars['Int']
}

export type RegisterNotionDatabases = {
  __typename?: 'RegisterNotionDatabases'
  integrations: RegisterNotionDatabasesIntegrations
}

export type RegisterNotionDatabasesInput = {
  integrations: RegisterNotionDatabasesIntegrationsInput
}

export type RegisterNotionDatabasesIntegrations = {
  __typename?: 'RegisterNotionDatabasesIntegrations'
  id: Scalars['String']
}

export type RegisterNotionDatabasesIntegrationsInput = {
  id: Scalars['String']
}

export type RegisterTenant = {
  __typename?: 'RegisterTenant'
  api: Api
  appId: Scalars['String']
  firstTimePassword: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  notion?: Maybe<NotionApiConfig>
  owner: User
  stripeAccountId: Scalars['String']
  stripeCustomerId: Scalars['String']
  subscribe: Scalars['Boolean']
}

export type RegisterTenantInput = {
  api?: InputMaybe<ApiInput>
  beta?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  notion?: InputMaybe<NotionApiConfigInput>
  owner: RegisterUserInput
  stripeAccountId?: InputMaybe<Scalars['String']>
  stripeCustomerId?: InputMaybe<Scalars['String']>
  subscribe: Scalars['Boolean']
}

export type RegisterUserInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}

export type Remote = {
  __typename?: 'Remote'
  city?: Maybe<Scalars['String']>
  continentCode?: Maybe<Scalars['String']>
  continentName?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  countryName?: Maybe<Scalars['String']>
  currencyCode?: Maybe<Scalars['String']>
  geohash?: Maybe<Scalars['String']>
  geonameId?: Maybe<Scalars['Int']>
  ip: Scalars['String']
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  regionCode?: Maybe<Scalars['String']>
  regionName?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  timezoneCode?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type RemoveDomainInput = {
  id: Scalars['String']
}

export type RemovePaymentMethodInput = {
  id: Scalars['ID']
}

export type ReorderFeaturedInput = {
  featured: Array<UpdateFeaturedInput>
}

export type Report = {
  __typename?: 'Report'
  action: ReportAction
  browser: BrowserState
  /** The ID of the content being played in the video player */
  contentId: Scalars['String']
  dayOfWeek: Scalars['Int']
  duration: Scalars['Int']
  durationBuffering: Scalars['Int']
  error?: Maybe<ReportError>
  hourOfDay: Scalars['Int']
  position: Scalars['Float']
  remote?: Maybe<Remote>
  timestamp: Scalars['DateTime']
  videoHeight: Scalars['Int']
  /** A UUID that identifies the video's current view (session) */
  viewId: Scalars['String']
  /** A UUID that identifies the authenticated user or cached viewer when not authenticated */
  viewerId?: Maybe<Scalars['String']>
}

export enum ReportAction {
  Buffer = 'buffer',
  Complete = 'complete',
  Error = 'error',
  Pause = 'pause',
  Play = 'play',
  Quality = 'quality',
  Seek = 'seek',
  Setup = 'setup',
  Time = 'time',
}

export type ReportError = {
  __typename?: 'ReportError'
  code: Scalars['String']
  data?: Maybe<Scalars['String']>
  message: Scalars['String']
  source?: Maybe<Scalars['String']>
}

export type ReportErrorInput = {
  code: Scalars['String']
  data?: InputMaybe<Scalars['String']>
  message: Scalars['String']
  source?: InputMaybe<Scalars['String']>
}

export type ReportInput = {
  action: ReportAction
  browser: BrowserStateInput
  /** The ID of the content being played in the video player */
  contentId: Scalars['String']
  /** The day of the week in local time that the report was generated */
  dayOfWeek: Scalars['Int']
  duration: Scalars['Int']
  durationBuffering: Scalars['Int']
  error?: InputMaybe<ReportErrorInput>
  /** The hour of the day in local time that the report was generated */
  hourOfDay: Scalars['Int']
  position: Scalars['Float']
  /** The time the report was generated */
  timestamp: Scalars['String']
  /** The total duration of the video in seconds */
  totalDuration: Scalars['Int']
  videoHeight: Scalars['Int']
  /** A UUID that identifies the video player session */
  viewId: Scalars['String']
  /** A UUID that identifies the viewer when not authenticated, otherwise the authenticated user ID is used instead */
  viewerId?: InputMaybe<Scalars['String']>
}

export type RepositoriesInput = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type Repository = Record & {
  __typename?: 'Repository'
  access: RepositoryAccess
  createdAt: Scalars['DateTime']
  error?: Maybe<Scalars['String']>
  hostname: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  owner: Scalars['String']
  pathname: Scalars['String']
  projectName: RepositoryProjectName
  stage: RepositoryStage
  status: RepositoryStatus
  updatedAt: Scalars['DateTime']
  url: Scalars['String']
}

export enum RepositoryAccess {
  Custom = 'custom',
  GithubApp = 'github_app',
  GithubCloud = 'github_cloud',
  GithubServer = 'github_server',
}

export enum RepositoryAccessInput {
  Custom = 'custom',
  GithubApp = 'github_app',
  GithubServer = 'github_server',
}

export type RepositoryAuth = {
  __typename?: 'RepositoryAuth'
  access: RepositoryAccess
  accessToken?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
  hostname: Scalars['String']
  name: Scalars['String']
  owner: Scalars['String']
  pathname: Scalars['String']
  projectName: RepositoryProjectName
  stage: RepositoryStage
  status: RepositoryStatus
}

export type RepositoryAuthInput = {
  appId: Scalars['String']
  projectName: RepositoryProjectName
  stage: RepositoryStage
}

export enum RepositoryProjectName {
  Viewcastle = 'viewcastle',
  ViewcastleMobile = 'viewcastle_mobile',
  ViewcastlePortalWeb = 'viewcastle_portal_web',
  ViewcastleTv = 'viewcastle_tv',
}

export enum RepositoryStage {
  Beta = 'beta',
  Prod = 'prod',
  Test = 'test',
}

export enum RepositoryStatus {
  Deploying = 'deploying',
  Error = 'error',
  Ok = 'ok',
  Pending = 'pending',
  Waiting = 'waiting',
}

export enum RequestSource {
  CustomerPortal = 'customerPortal',
  Ott = 'ott',
}

export type Res = {
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  /** The total number of items */
  total: Scalars['Int']
}

export type ResetPasswordInput = {
  password: Scalars['String']
  token: Scalars['String']
}

export enum Role {
  Admin = 'admin',
  Customer = 'customer',
  Owner = 'owner',
}

export type Route = {
  __typename?: 'Route'
  pageId: Scalars['String']
  path: Scalars['String']
}

/** A type that represents a row of media items to be rendered on a page */
export type Row = Record & {
  __typename?: 'Row'
  catalog?: Maybe<Catalog>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  order: Scalars['Int']
  page: RowPage
  product?: Maybe<Product>
  /** The title of the row which defaults to either the category name or the name of the automated row. */
  title: Scalars['String']
  type: RowType
  updatedAt: Scalars['DateTime']
}

export type RowInput = {
  customPageId?: InputMaybe<Scalars['String']>
  customPageSlug?: InputMaybe<Scalars['String']>
  page: RowPage
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

/** The page that the rows are rendered on. */
export enum RowPage {
  Custom = 'custom',
  Explore = 'explore',
  Home = 'home',
  Library = 'library',
}

export type RowResponse = Res & {
  __typename?: 'RowResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Row>
  /** The total number of items */
  total: Scalars['Int']
}

/** Catalog rows pull their content from the catalog's set of filters, otherwise the other types are automatic. */
export enum RowType {
  AvailableForYou = 'availableForYou',
  AvailableOnDemand = 'availableOnDemand',
  Catalog = 'catalog',
  Favorites = 'favorites',
  IncludedWithSubscription = 'includedWithSubscription',
  KeepWatching = 'keepWatching',
  LiveAndUpcomingEvents = 'liveAndUpcomingEvents',
  New = 'new',
  PastEvents = 'pastEvents',
  Purchases = 'purchases',
  Recent = 'recent',
  RecentlyWatched = 'recentlyWatched',
  RegisterToWatch = 'registerToWatch',
  TopResults = 'topResults',
  Upcoming = 'upcoming',
  WatchHistory = 'watchHistory',
}

export type Rows = {
  __typename?: 'Rows'
  rows: Array<Row>
}

export type SetupIntent = {
  __typename?: 'SetupIntent'
  clientSecret: Scalars['String']
}

export type SinglePageInput = {
  id?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
}

export type Stack = {
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  version?: Maybe<Scalars['String']>
}

export type StripeAccountLink = {
  __typename?: 'StripeAccountLink'
  createdAt: Scalars['DateTime']
  expiresAt: Scalars['DateTime']
  object: StripeObjectType
  url: Scalars['String']
}

export type StripeAccountLinkInput = {
  refreshUrl: Scalars['String']
  returnUrl: Scalars['String']
  type: StripeAccountLinkType
}

export enum StripeAccountLinkType {
  Onboarding = 'onboarding',
  Update = 'update',
}

export type StripeObject = {
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  livemode: Scalars['Boolean']
  metadata: Array<Metadata>
  object: StripeObjectType
  updatedAt: Scalars['DateTime']
}

export enum StripeObjectType {
  Account = 'account',
  AccountLink = 'account_link',
  Balance = 'balance',
  BalanceTransaction = 'balance_transaction',
  Charge = 'charge',
  CountrySpec = 'country_spec',
  Coupon = 'coupon',
  Customer = 'customer',
  Discount = 'discount',
  Dispute = 'dispute',
  Event = 'event',
  File = 'file',
  FileLink = 'file_link',
  Invoice = 'invoice',
  InvoiceItem = 'invoice_item',
  LineItem = 'line_item',
  LoginLink = 'login_link',
  PaymentIntent = 'payment_intent',
  PaymentMethod = 'payment_method',
  Payout = 'payout',
  Plan = 'plan',
  Price = 'price',
  Product = 'product',
  Refund = 'refund',
  ReportingReportType = 'reporting_report_type',
  Review = 'review',
  SetupIntent = 'setup_intent',
  Sku = 'sku',
  Source = 'source',
  Subscription = 'subscription',
  SubscriptionItem = 'subscription_item',
  TaxId = 'tax_id',
  TaxRate = 'tax_rate',
  Topup = 'topup',
  Transfer = 'transfer',
  TransferReversal = 'transfer_reversal',
  UsageRecord = 'usage_record',
  UsageRecordSummary = 'usage_record_summary',
}

export enum StripeOnboardingStatus {
  Complete = 'complete',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
  Pending = 'pending',
}

export type Subscription = {
  __typename?: 'Subscription'
  id?: Maybe<Scalars['ID']>
  items: Array<SubscriptionItem>
  status: SubscriptionStatus
}

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem'
  id?: Maybe<Scalars['ID']>
  price: Price
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export type Tag = {
  __typename?: 'Tag'
  color?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export enum TaxBehavior {
  Exclusive = 'exclusive',
  Inclusive = 'inclusive',
  Unspecified = 'unspecified',
}

export type Tenant = Record & {
  __typename?: 'Tenant'
  api: ApiType
  createdAt: Scalars['DateTime']
  environment?: Maybe<Environment>
  id: Scalars['ID']
  logo?: Maybe<Image>
  name: Scalars['String']
  repositories: Array<Repository>
  stripeAccountId?: Maybe<Scalars['String']>
  stripeCustomerId?: Maybe<Scalars['String']>
  stripeOnboardingStatus: StripeOnboardingStatus
  updatedAt: Scalars['DateTime']
  users: Array<User>
}

export type UpdateEnvironmentInput = {
  accountId: Scalars['String']
  frontendStack: UpdateFrontendStackInput
  name: Scalars['String']
  tenantId: Scalars['String']
  vodStack: UpdateVodStackInput
}

export type UpdateFeaturedInput = {
  id: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
}

export type UpdateFrontendStackInput = {
  buildBucket: Scalars['String']
  customerPortalBucket: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  ottBucket: Scalars['String']
  version?: InputMaybe<Scalars['String']>
}

export type UpdateMeInput = {
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
}

export type UpdatePasswordInput = {
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type UpdatePaymentMethodInput = {
  expMonth?: InputMaybe<Scalars['Int']>
  expYear?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  primary?: InputMaybe<Scalars['Boolean']>
}

export type UpdateTenantInput = {
  logo?: InputMaybe<ImageInput>
  name?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  lastName?: InputMaybe<Scalars['String']>
}

export type UpdateVodStackInput = {
  cloudFrontDomain: Scalars['String']
  destBucket: Scalars['String']
  destBucketRole: Scalars['String']
  destBucketRoleArn: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  srcBucket: Scalars['String']
  srcBucketRole: Scalars['String']
  srcBucketRoleArn: Scalars['String']
  version?: InputMaybe<Scalars['String']>
}

export type UpdateWatchlistInput = {
  contentId: Scalars['String']
}

export type UpsertCatalogInput = {
  bundleDescription?: InputMaybe<Scalars['String']>
  bundleEnabled?: InputMaybe<Scalars['Boolean']>
  bundleTitle?: InputMaybe<Scalars['String']>
  categoryIds: Array<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  expireOn?: InputMaybe<Scalars['DateTime']>
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
}

export type UpsertCategoryInput = {
  description?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  expireOn?: InputMaybe<Scalars['DateTime']>
  gate?: InputMaybe<GateType>
  gateEnabled: Scalars['Boolean']
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
}

export type UpsertEventInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  downloadsEnabled?: InputMaybe<Scalars['Boolean']>
  enabled?: InputMaybe<Scalars['Boolean']>
  expireOn?: InputMaybe<Scalars['DateTime']>
  featured?: InputMaybe<Scalars['Boolean']>
  fileName?: InputMaybe<Scalars['String']>
  gate?: InputMaybe<GateType>
  gateEnabled?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  logoFileName?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Array<UpsertMetadataInput>>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
  scheduledEnd?: InputMaybe<Scalars['DateTime']>
  scheduledStart?: InputMaybe<Scalars['DateTime']>
  sixteenNineCoverFileName?: InputMaybe<Scalars['String']>
  subtitle?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<UpsertTagInput>>
  tallBackdropFileName?: InputMaybe<Scalars['String']>
  twoThreeCoverFileName?: InputMaybe<Scalars['String']>
  wideBackdropFileName?: InputMaybe<Scalars['String']>
}

export type UpsertGateInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  originId?: InputMaybe<Scalars['String']>
  type: GateType
}

export type UpsertIntegrationDetailInput = {
  enabled?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  integrationId?: InputMaybe<Scalars['String']>
}

export type UpsertLinkInput = {
  id?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  url: Scalars['String']
}

export type UpsertLinksInput = {
  links: Array<UpsertLinkInput>
}

export type UpsertMetadataInput = {
  name: Scalars['String']
  value: Scalars['String']
}

export type UpsertPageInput = {
  bannerImageFileName?: InputMaybe<Scalars['String']>
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
  expireOn?: InputMaybe<Scalars['DateTime']>
  gate?: InputMaybe<GateType>
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
  publishOn?: InputMaybe<Scalars['DateTime']>
  slug?: InputMaybe<Scalars['String']>
}

export type UpsertPagesInput = {
  pages: Array<UpsertPageInput>
}

export type UpsertProductInput = {
  description?: InputMaybe<Scalars['String']>
  iapSkuAndroid?: InputMaybe<Scalars['String']>
  iapSkuIos?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  interval?: InputMaybe<Interval>
  name: Scalars['String']
  price?: InputMaybe<Scalars['Int']>
  recurring?: InputMaybe<Scalars['Boolean']>
}

export type UpsertRepositoryInput = {
  access?: InputMaybe<RepositoryAccessInput>
  hostname: Scalars['String']
  name: Scalars['String']
  owner: Scalars['String']
  projectName: RepositoryProjectName
  tenantId?: InputMaybe<Scalars['String']>
}

export type UpsertRowInput = {
  catalogId?: InputMaybe<Scalars['String']>
  customPageId?: InputMaybe<Scalars['String']>
  customPageSlug?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  order?: InputMaybe<Scalars['Int']>
  page: RowPage
  productId?: InputMaybe<Scalars['String']>
  title: Scalars['String']
  type: RowType
}

export type UpsertRowsInput = {
  rows: Array<UpsertRowsInterface>
}

export type UpsertRowsInterface = {
  catalogId?: InputMaybe<Scalars['String']>
  customPageId?: InputMaybe<Scalars['String']>
  customPageSlug?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  order?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<RowPage>
  productId?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<RowType>
}

export type UpsertTagInput = {
  color?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type UpsertVideoInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  downloadsEnabled?: InputMaybe<Scalars['Boolean']>
  enabled?: InputMaybe<Scalars['Boolean']>
  expireOn?: InputMaybe<Scalars['DateTime']>
  featured?: InputMaybe<Scalars['Boolean']>
  fileName?: InputMaybe<Scalars['String']>
  gate?: InputMaybe<GateType>
  gateEnabled?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  logoFileName?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Array<UpsertMetadataInput>>
  name: Scalars['String']
  productId?: InputMaybe<Scalars['String']>
  publishOn?: InputMaybe<Scalars['DateTime']>
  sixteenNineCoverFileName?: InputMaybe<Scalars['String']>
  subtitle?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<UpsertTagInput>>
  tallBackdropFileName?: InputMaybe<Scalars['String']>
  twoThreeCoverFileName?: InputMaybe<Scalars['String']>
  wideBackdropFileName?: InputMaybe<Scalars['String']>
}

export type User = Record & {
  __typename?: 'User'
  createdAt: Scalars['DateTime']
  customer?: Maybe<Customer>
  email: Scalars['String']
  favorites: Array<MediaItem>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  roles: Array<Role>
  tenant?: Maybe<Tenant>
  token?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type UserInput = {
  roles?: InputMaybe<Array<InputMaybe<Role>>>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type UserResponse = Res & {
  __typename?: 'UserResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<User>
  /** The total number of items */
  total: Scalars['Int']
}

export type UserRole = {
  __typename?: 'UserRole'
  name: Role
}

export type VerifyCheckoutSessionInput = {
  bundleId?: InputMaybe<Scalars['String']>
  checkoutSessionId: Scalars['String']
  contentId: Scalars['String']
  priceId?: InputMaybe<Scalars['String']>
}

export type Video = Content &
  Entry &
  Record & {
    __typename?: 'Video'
    appliedGates: Array<Gate>
    bundles: Array<Bundle>
    categories: Array<Category>
    createdAt: Scalars['DateTime']
    description?: Maybe<Scalars['String']>
    downloadUrl?: Maybe<Scalars['String']>
    downloadsEnabled: Scalars['Boolean']
    /** Duration in seconds */
    duration?: Maybe<Scalars['Int']>
    enabled: Scalars['Boolean']
    expireOn?: Maybe<Scalars['DateTime']>
    favorited?: Maybe<Scalars['Boolean']>
    featured: Scalars['Boolean']
    featuredOrder?: Maybe<Scalars['Int']>
    fileName?: Maybe<Scalars['String']>
    gate?: Maybe<Gate>
    gateEnabled: Scalars['Boolean']
    id: Scalars['ID']
    logo?: Maybe<Image>
    metadata: Array<Metadata>
    name: Scalars['String']
    originId?: Maybe<Scalars['String']>
    publishOn?: Maybe<Scalars['DateTime']>
    purchased?: Maybe<Scalars['Boolean']>
    restrictions?: Maybe<Array<ContentRestrictionsType>>
    sixteenNineCover?: Maybe<Image>
    srcVideo?: Maybe<Scalars['String']>
    status: EntryStatus
    subtitle?: Maybe<Scalars['String']>
    tags: Array<Maybe<Tag>>
    tallBackdrop?: Maybe<Image>
    twoThreeCover?: Maybe<Image>
    type: ContentType
    updatedAt: Scalars['DateTime']
    /** The last view time and progress of the content by the user if authenticated */
    viewer?: Maybe<Viewer>
    wideBackdrop?: Maybe<Image>
    workflowId?: Maybe<Scalars['String']>
    workflowStatus: WorkflowStatus
  }

export type VideoResponse = Res & {
  __typename?: 'VideoResponse'
  /** Whether there are more items to fetch */
  hasMore: Scalars['Boolean']
  list: Array<Video>
  /** The total number of items */
  total: Scalars['Int']
}

export type Viewer = {
  __typename?: 'Viewer'
  /** The viewer's last position when viewing the content in seconds */
  lastPosition: Scalars['Int']
  /** The date and time the user last viewed the content */
  lastViewedAt: Scalars['DateTime']
  /** The viewer's progress between 0 and 1 */
  progress?: Maybe<Scalars['Float']>
}

export type VodStack = Record &
  Stack & {
    __typename?: 'VodStack'
    cloudFrontDomain: Scalars['String']
    createdAt: Scalars['DateTime']
    destBucket: Scalars['String']
    destBucketRole: Scalars['String']
    destBucketRoleArn: Scalars['String']
    id: Scalars['ID']
    name?: Maybe<Scalars['String']>
    srcBucket: Scalars['String']
    srcBucketRole: Scalars['String']
    srcBucketRoleArn: Scalars['String']
    updatedAt: Scalars['DateTime']
    version?: Maybe<Scalars['String']>
  }

export enum WorkflowStatus {
  Complete = 'complete',
  Error = 'error',
  None = 'none',
  Processing = 'processing',
}

export type FooterFieldsFragment = {
  __typename?: 'Footer'
  description?: string | null
  copyright: string
  links: Array<{
    __typename?: 'Link'
    type: LinkType
    text: string
    url?: string | null
    custom?: boolean | null
    icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    route?: { __typename?: 'Route'; path: string; pageId: string } | null
  } | null>
}

export type HeaderFieldsFragment = {
  __typename?: 'Header'
  brand: {
    __typename?: 'Link'
    type: LinkType
    text: string
    url?: string | null
    custom?: boolean | null
    icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    route?: { __typename?: 'Route'; path: string; pageId: string } | null
  }
  links: Array<{
    __typename?: 'Link'
    type: LinkType
    text: string
    url?: string | null
    custom?: boolean | null
    icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    route?: { __typename?: 'Route'; path: string; pageId: string } | null
  }>
  more: Array<{
    __typename?: 'Link'
    type: LinkType
    text: string
    url?: string | null
    custom?: boolean | null
    icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    route?: { __typename?: 'Route'; path: string; pageId: string } | null
  }>
}

export type LinkFieldsFragment = {
  __typename?: 'Link'
  type: LinkType
  text: string
  url?: string | null
  custom?: boolean | null
  icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  route?: { __typename?: 'Route'; path: string; pageId: string } | null
}

export type ImageFieldsFragment = { __typename?: 'Image'; src: string; width?: number | null; height?: number | null }

export type RouteFieldsFragment = { __typename?: 'Route'; path: string; pageId: string }

export type GlobalFieldsFragment = {
  __typename?: 'Global'
  footer: {
    __typename?: 'Footer'
    description?: string | null
    copyright: string
    links: Array<{
      __typename?: 'Link'
      type: LinkType
      text: string
      url?: string | null
      custom?: boolean | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      route?: { __typename?: 'Route'; path: string; pageId: string } | null
    } | null>
  }
  header: {
    __typename?: 'Header'
    brand: {
      __typename?: 'Link'
      type: LinkType
      text: string
      url?: string | null
      custom?: boolean | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      route?: { __typename?: 'Route'; path: string; pageId: string } | null
    }
    links: Array<{
      __typename?: 'Link'
      type: LinkType
      text: string
      url?: string | null
      custom?: boolean | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      route?: { __typename?: 'Route'; path: string; pageId: string } | null
    }>
    more: Array<{
      __typename?: 'Link'
      type: LinkType
      text: string
      url?: string | null
      custom?: boolean | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      route?: { __typename?: 'Route'; path: string; pageId: string } | null
    }>
  }
}

export type UserFieldsFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  createdAt: any
  updatedAt: any
  email: string
  token?: string | null
}

export type SubscriptionItemFieldsFragment = {
  __typename?: 'SubscriptionItem'
  id?: string | null
  price: {
    __typename?: 'Price'
    id: string
    currency: Currency
    product: string
    amount: number
    type: PriceType
    taxBehavior: TaxBehavior
    iapSkuIos?: string | null
    iapSkuAndroid?: string | null
    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
  }
}

export type SubscriptionFieldsFragment = {
  __typename?: 'Subscription'
  id?: string | null
  status: SubscriptionStatus
  items: Array<{
    __typename?: 'SubscriptionItem'
    id?: string | null
    price: {
      __typename?: 'Price'
      id: string
      currency: Currency
      product: string
      amount: number
      type: PriceType
      taxBehavior: TaxBehavior
      iapSkuIos?: string | null
      iapSkuAndroid?: string | null
      recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
    }
  }>
}

export type CustomerFieldsFragment = {
  __typename?: 'Customer'
  id?: string | null
  subscriptions: Array<{
    __typename?: 'Subscription'
    id?: string | null
    status: SubscriptionStatus
    items: Array<{
      __typename?: 'SubscriptionItem'
      id?: string | null
      price: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }
    }>
  }>
}

export type VideoFieldsFragment = {
  __typename?: 'Video'
  id: string
  createdAt: any
  updatedAt: any
  name: string
  subtitle?: string | null
  description?: string | null
  publishOn?: any | null
  expireOn?: any | null
  enabled: boolean
  status: EntryStatus
  downloadsEnabled: boolean
  duration?: number | null
  featured: boolean
  type: ContentType
  favorited?: boolean | null
  purchased?: boolean | null
  restrictions?: Array<ContentRestrictionsType> | null
  appliedGates: Array<{
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
  }>
  viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
  logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  categories: Array<{
    __typename?: 'Category'
    id: string
    createdAt: any
    updatedAt: any
    name: string
    description?: string | null
    gateEnabled: boolean
    publishOn?: any | null
    expireOn?: any | null
    enabled: boolean
    status: EntryStatus
    downloadsEnabled: boolean
  }>
  metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
  bundles: Array<{
    __typename?: 'Bundle'
    id: string
    title: string
    description?: string | null
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
    items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
  }>
}

export type EventFieldsFragment = {
  __typename?: 'Event'
  scheduledEnd?: any | null
  scheduledStart?: any | null
  liveNow: boolean
  id: string
  createdAt: any
  updatedAt: any
  name: string
  subtitle?: string | null
  description?: string | null
  publishOn?: any | null
  expireOn?: any | null
  enabled: boolean
  status: EntryStatus
  downloadsEnabled: boolean
  duration?: number | null
  featured: boolean
  type: ContentType
  favorited?: boolean | null
  purchased?: boolean | null
  restrictions?: Array<ContentRestrictionsType> | null
  appliedGates: Array<{
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
  }>
  viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
  logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  categories: Array<{
    __typename?: 'Category'
    id: string
    createdAt: any
    updatedAt: any
    name: string
    description?: string | null
    gateEnabled: boolean
    publishOn?: any | null
    expireOn?: any | null
    enabled: boolean
    status: EntryStatus
    downloadsEnabled: boolean
  }>
  metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
  bundles: Array<{
    __typename?: 'Bundle'
    id: string
    title: string
    description?: string | null
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
    items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
  }>
}

type MediaItemFields_Event_Fragment = {
  __typename?: 'Event'
  scheduledEnd?: any | null
  scheduledStart?: any | null
  liveNow: boolean
  id: string
  createdAt: any
  updatedAt: any
  name: string
  subtitle?: string | null
  description?: string | null
  publishOn?: any | null
  expireOn?: any | null
  enabled: boolean
  status: EntryStatus
  downloadsEnabled: boolean
  duration?: number | null
  featured: boolean
  type: ContentType
  favorited?: boolean | null
  purchased?: boolean | null
  restrictions?: Array<ContentRestrictionsType> | null
  appliedGates: Array<{
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
  }>
  viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
  logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  categories: Array<{
    __typename?: 'Category'
    id: string
    createdAt: any
    updatedAt: any
    name: string
    description?: string | null
    gateEnabled: boolean
    publishOn?: any | null
    expireOn?: any | null
    enabled: boolean
    status: EntryStatus
    downloadsEnabled: boolean
  }>
  metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
  bundles: Array<{
    __typename?: 'Bundle'
    id: string
    title: string
    description?: string | null
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
    items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
  }>
}

type MediaItemFields_Video_Fragment = {
  __typename?: 'Video'
  id: string
  createdAt: any
  updatedAt: any
  name: string
  subtitle?: string | null
  description?: string | null
  publishOn?: any | null
  expireOn?: any | null
  enabled: boolean
  status: EntryStatus
  downloadsEnabled: boolean
  duration?: number | null
  featured: boolean
  type: ContentType
  favorited?: boolean | null
  purchased?: boolean | null
  restrictions?: Array<ContentRestrictionsType> | null
  appliedGates: Array<{
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
  }>
  viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
  logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  categories: Array<{
    __typename?: 'Category'
    id: string
    createdAt: any
    updatedAt: any
    name: string
    description?: string | null
    gateEnabled: boolean
    publishOn?: any | null
    expireOn?: any | null
    enabled: boolean
    status: EntryStatus
    downloadsEnabled: boolean
  }>
  metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
  bundles: Array<{
    __typename?: 'Bundle'
    id: string
    title: string
    description?: string | null
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
    items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
  }>
}

export type MediaItemFieldsFragment = MediaItemFields_Event_Fragment | MediaItemFields_Video_Fragment

export type RecurringFieldsFragment = { __typename?: 'Recurring'; interval: Interval; intervalCount: number }

export type PriceFieldsFragment = {
  __typename?: 'Price'
  id: string
  currency: Currency
  product: string
  amount: number
  type: PriceType
  taxBehavior: TaxBehavior
  iapSkuIos?: string | null
  iapSkuAndroid?: string | null
  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
}

export type ProductFieldsFragment = {
  __typename?: 'Product'
  id: string
  name: string
  description?: string | null
  originId: string
  url?: string | null
  unitLabel?: string | null
  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
  defaultPrice?: {
    __typename?: 'Price'
    id: string
    currency: Currency
    product: string
    amount: number
    type: PriceType
    taxBehavior: TaxBehavior
    iapSkuIos?: string | null
    iapSkuAndroid?: string | null
    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
  } | null
  prices: Array<{
    __typename?: 'Price'
    id: string
    currency: Currency
    product: string
    amount: number
    type: PriceType
    taxBehavior: TaxBehavior
    iapSkuIos?: string | null
    iapSkuAndroid?: string | null
    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
  }>
}

export type GateFieldsFragment = {
  __typename?: 'Gate'
  id: string
  type: GateType
  product?: {
    __typename?: 'Product'
    id: string
    name: string
    description?: string | null
    originId: string
    url?: string | null
    unitLabel?: string | null
    images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
    defaultPrice?: {
      __typename?: 'Price'
      id: string
      currency: Currency
      product: string
      amount: number
      type: PriceType
      taxBehavior: TaxBehavior
      iapSkuIos?: string | null
      iapSkuAndroid?: string | null
      recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
    } | null
    prices: Array<{
      __typename?: 'Price'
      id: string
      currency: Currency
      product: string
      amount: number
      type: PriceType
      taxBehavior: TaxBehavior
      iapSkuIos?: string | null
      iapSkuAndroid?: string | null
      recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
    }>
  } | null
}

export type ViewerFieldsFragment = {
  __typename?: 'Viewer'
  lastViewedAt: any
  lastPosition: number
  progress?: number | null
}

export type MetadataFieldsFragment = { __typename?: 'Metadata'; name: string; value?: string | null }

type ContentFields_Event_Fragment = {
  __typename?: 'Event'
  id: string
  createdAt: any
  updatedAt: any
  name: string
  subtitle?: string | null
  description?: string | null
  publishOn?: any | null
  expireOn?: any | null
  enabled: boolean
  status: EntryStatus
  downloadsEnabled: boolean
  duration?: number | null
  featured: boolean
  type: ContentType
  favorited?: boolean | null
  purchased?: boolean | null
  restrictions?: Array<ContentRestrictionsType> | null
  appliedGates: Array<{
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
  }>
  viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
  logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  categories: Array<{
    __typename?: 'Category'
    id: string
    createdAt: any
    updatedAt: any
    name: string
    description?: string | null
    gateEnabled: boolean
    publishOn?: any | null
    expireOn?: any | null
    enabled: boolean
    status: EntryStatus
    downloadsEnabled: boolean
  }>
  metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
  bundles: Array<{
    __typename?: 'Bundle'
    id: string
    title: string
    description?: string | null
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
    items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
  }>
}

type ContentFields_Video_Fragment = {
  __typename?: 'Video'
  id: string
  createdAt: any
  updatedAt: any
  name: string
  subtitle?: string | null
  description?: string | null
  publishOn?: any | null
  expireOn?: any | null
  enabled: boolean
  status: EntryStatus
  downloadsEnabled: boolean
  duration?: number | null
  featured: boolean
  type: ContentType
  favorited?: boolean | null
  purchased?: boolean | null
  restrictions?: Array<ContentRestrictionsType> | null
  appliedGates: Array<{
    __typename?: 'Gate'
    id: string
    type: GateType
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
  }>
  viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
  logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  categories: Array<{
    __typename?: 'Category'
    id: string
    createdAt: any
    updatedAt: any
    name: string
    description?: string | null
    gateEnabled: boolean
    publishOn?: any | null
    expireOn?: any | null
    enabled: boolean
    status: EntryStatus
    downloadsEnabled: boolean
  }>
  metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
  bundles: Array<{
    __typename?: 'Bundle'
    id: string
    title: string
    description?: string | null
    product?: {
      __typename?: 'Product'
      id: string
      name: string
      description?: string | null
      originId: string
      url?: string | null
      unitLabel?: string | null
      images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
      defaultPrice?: {
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      } | null
      prices: Array<{
        __typename?: 'Price'
        id: string
        currency: Currency
        product: string
        amount: number
        type: PriceType
        taxBehavior: TaxBehavior
        iapSkuIos?: string | null
        iapSkuAndroid?: string | null
        recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
      }>
    } | null
    items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
  }>
}

export type ContentFieldsFragment = ContentFields_Event_Fragment | ContentFields_Video_Fragment

export type ChannelFieldsFragment = { __typename?: 'Channel'; id: string; name: string; description?: string | null }

export type CategoryFieldsFragment = {
  __typename?: 'Category'
  id: string
  createdAt: any
  updatedAt: any
  name: string
  description?: string | null
  gateEnabled: boolean
  publishOn?: any | null
  expireOn?: any | null
  enabled: boolean
  status: EntryStatus
  downloadsEnabled: boolean
}

export type MediaItemResponseFieldsFragment = {
  __typename?: 'MediaItemResponse'
  total: number
  hasMore: boolean
  list: Array<
    | {
        __typename?: 'Event'
        scheduledEnd?: any | null
        scheduledStart?: any | null
        liveNow: boolean
        id: string
        createdAt: any
        updatedAt: any
        name: string
        subtitle?: string | null
        description?: string | null
        publishOn?: any | null
        expireOn?: any | null
        enabled: boolean
        status: EntryStatus
        downloadsEnabled: boolean
        duration?: number | null
        featured: boolean
        type: ContentType
        favorited?: boolean | null
        purchased?: boolean | null
        restrictions?: Array<ContentRestrictionsType> | null
        appliedGates: Array<{
          __typename?: 'Gate'
          id: string
          type: GateType
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
        }>
        viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
        logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        categories: Array<{
          __typename?: 'Category'
          id: string
          createdAt: any
          updatedAt: any
          name: string
          description?: string | null
          gateEnabled: boolean
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
        }>
        metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
        bundles: Array<{
          __typename?: 'Bundle'
          id: string
          title: string
          description?: string | null
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
          items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
        }>
      }
    | {
        __typename?: 'Video'
        id: string
        createdAt: any
        updatedAt: any
        name: string
        subtitle?: string | null
        description?: string | null
        publishOn?: any | null
        expireOn?: any | null
        enabled: boolean
        status: EntryStatus
        downloadsEnabled: boolean
        duration?: number | null
        featured: boolean
        type: ContentType
        favorited?: boolean | null
        purchased?: boolean | null
        restrictions?: Array<ContentRestrictionsType> | null
        appliedGates: Array<{
          __typename?: 'Gate'
          id: string
          type: GateType
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
        }>
        viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
        logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        categories: Array<{
          __typename?: 'Category'
          id: string
          createdAt: any
          updatedAt: any
          name: string
          description?: string | null
          gateEnabled: boolean
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
        }>
        metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
        bundles: Array<{
          __typename?: 'Bundle'
          id: string
          title: string
          description?: string | null
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
          items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
        }>
      }
  >
}

export type MediaRowFieldsFragment = {
  __typename?: 'MediaRow'
  id: string
  title: string
  type: MediaRowType
  catalogId?: string | null
  icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  items: {
    __typename?: 'MediaItemResponse'
    total: number
    hasMore: boolean
    list: Array<
      | {
          __typename?: 'Event'
          scheduledEnd?: any | null
          scheduledStart?: any | null
          liveNow: boolean
          id: string
          createdAt: any
          updatedAt: any
          name: string
          subtitle?: string | null
          description?: string | null
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
          duration?: number | null
          featured: boolean
          type: ContentType
          favorited?: boolean | null
          purchased?: boolean | null
          restrictions?: Array<ContentRestrictionsType> | null
          appliedGates: Array<{
            __typename?: 'Gate'
            id: string
            type: GateType
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
          }>
          viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
          logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          categories: Array<{
            __typename?: 'Category'
            id: string
            createdAt: any
            updatedAt: any
            name: string
            description?: string | null
            gateEnabled: boolean
            publishOn?: any | null
            expireOn?: any | null
            enabled: boolean
            status: EntryStatus
            downloadsEnabled: boolean
          }>
          metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
          bundles: Array<{
            __typename?: 'Bundle'
            id: string
            title: string
            description?: string | null
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
            items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
          }>
        }
      | {
          __typename?: 'Video'
          id: string
          createdAt: any
          updatedAt: any
          name: string
          subtitle?: string | null
          description?: string | null
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
          duration?: number | null
          featured: boolean
          type: ContentType
          favorited?: boolean | null
          purchased?: boolean | null
          restrictions?: Array<ContentRestrictionsType> | null
          appliedGates: Array<{
            __typename?: 'Gate'
            id: string
            type: GateType
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
          }>
          viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
          logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          categories: Array<{
            __typename?: 'Category'
            id: string
            createdAt: any
            updatedAt: any
            name: string
            description?: string | null
            gateEnabled: boolean
            publishOn?: any | null
            expireOn?: any | null
            enabled: boolean
            status: EntryStatus
            downloadsEnabled: boolean
          }>
          metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
          bundles: Array<{
            __typename?: 'Bundle'
            id: string
            title: string
            description?: string | null
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
            items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
          }>
        }
    >
  }
}

export type PlayerFieldsFragment = {
  __typename?: 'Player'
  signedUrl: string
  url: string
  position?: number | null
  contentId: string
}

export type PageFieldsFragment = {
  __typename?: 'Page'
  id: string
  name: string
  slug: string
  description?: string | null
  bannerImage?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
  rows: Array<{
    __typename?: 'MediaRow'
    id: string
    title: string
    type: MediaRowType
    catalogId?: string | null
    icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    items: {
      __typename?: 'MediaItemResponse'
      total: number
      hasMore: boolean
      list: Array<
        | {
            __typename?: 'Event'
            scheduledEnd?: any | null
            scheduledStart?: any | null
            liveNow: boolean
            id: string
            createdAt: any
            updatedAt: any
            name: string
            subtitle?: string | null
            description?: string | null
            publishOn?: any | null
            expireOn?: any | null
            enabled: boolean
            status: EntryStatus
            downloadsEnabled: boolean
            duration?: number | null
            featured: boolean
            type: ContentType
            favorited?: boolean | null
            purchased?: boolean | null
            restrictions?: Array<ContentRestrictionsType> | null
            appliedGates: Array<{
              __typename?: 'Gate'
              id: string
              type: GateType
              product?: {
                __typename?: 'Product'
                id: string
                name: string
                description?: string | null
                originId: string
                url?: string | null
                unitLabel?: string | null
                images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                defaultPrice?: {
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                } | null
                prices: Array<{
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                }>
              } | null
            }>
            viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
            logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            sixteenNineCover?: {
              __typename?: 'Image'
              src: string
              width?: number | null
              height?: number | null
            } | null
            twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            categories: Array<{
              __typename?: 'Category'
              id: string
              createdAt: any
              updatedAt: any
              name: string
              description?: string | null
              gateEnabled: boolean
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
            }>
            metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
            bundles: Array<{
              __typename?: 'Bundle'
              id: string
              title: string
              description?: string | null
              product?: {
                __typename?: 'Product'
                id: string
                name: string
                description?: string | null
                originId: string
                url?: string | null
                unitLabel?: string | null
                images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                defaultPrice?: {
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                } | null
                prices: Array<{
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                }>
              } | null
              items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
            }>
          }
        | {
            __typename?: 'Video'
            id: string
            createdAt: any
            updatedAt: any
            name: string
            subtitle?: string | null
            description?: string | null
            publishOn?: any | null
            expireOn?: any | null
            enabled: boolean
            status: EntryStatus
            downloadsEnabled: boolean
            duration?: number | null
            featured: boolean
            type: ContentType
            favorited?: boolean | null
            purchased?: boolean | null
            restrictions?: Array<ContentRestrictionsType> | null
            appliedGates: Array<{
              __typename?: 'Gate'
              id: string
              type: GateType
              product?: {
                __typename?: 'Product'
                id: string
                name: string
                description?: string | null
                originId: string
                url?: string | null
                unitLabel?: string | null
                images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                defaultPrice?: {
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                } | null
                prices: Array<{
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                }>
              } | null
            }>
            viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
            logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            sixteenNineCover?: {
              __typename?: 'Image'
              src: string
              width?: number | null
              height?: number | null
            } | null
            twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
            categories: Array<{
              __typename?: 'Category'
              id: string
              createdAt: any
              updatedAt: any
              name: string
              description?: string | null
              gateEnabled: boolean
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
            }>
            metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
            bundles: Array<{
              __typename?: 'Bundle'
              id: string
              title: string
              description?: string | null
              product?: {
                __typename?: 'Product'
                id: string
                name: string
                description?: string | null
                originId: string
                url?: string | null
                unitLabel?: string | null
                images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                defaultPrice?: {
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                } | null
                prices: Array<{
                  __typename?: 'Price'
                  id: string
                  currency: Currency
                  product: string
                  amount: number
                  type: PriceType
                  taxBehavior: TaxBehavior
                  iapSkuIos?: string | null
                  iapSkuAndroid?: string | null
                  recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                }>
              } | null
              items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
            }>
          }
      >
    }
  }>
}

export type LoginMutationVariables = Exact<{
  data: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'User'
    token?: string | null
    firstName?: string | null
    lastName?: string | null
    email: string
    roles: Array<Role>
    customer?: {
      __typename?: 'Customer'
      id?: string | null
      subscriptions: Array<{
        __typename?: 'Subscription'
        id?: string | null
        status: SubscriptionStatus
        items: Array<{
          __typename?: 'SubscriptionItem'
          id?: string | null
          price: {
            __typename?: 'Price'
            id: string
            currency: Currency
            product: string
            amount: number
            type: PriceType
            taxBehavior: TaxBehavior
            iapSkuIos?: string | null
            iapSkuAndroid?: string | null
            recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
          }
        }>
      }>
    } | null
  } | null
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout?: boolean | null }

export type RegisterTenantMutationVariables = Exact<{
  data: RegisterTenantInput
}>

export type RegisterTenantMutation = {
  __typename?: 'Mutation'
  registerTenant?: {
    __typename?: 'RegisterTenant'
    name: string
    id: string
    api: { __typename?: 'Api'; type: ApiType }
    owner: {
      __typename?: 'User'
      firstName?: string | null
      lastName?: string | null
      email: string
      roles: Array<Role>
    }
  } | null
}

export type ForgotPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput
}>

export type ForgotPasswordMutation = {
  __typename?: 'Mutation'
  forgotPassword?: { __typename?: 'MessageResponse'; message: string } | null
}

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword?: { __typename?: 'MessageResponse'; message: string } | null
}

export type UpdatePasswordMutationVariables = Exact<{
  data: UpdatePasswordInput
}>

export type UpdatePasswordMutation = {
  __typename?: 'Mutation'
  updatePassword?: { __typename?: 'MessageResponse'; message: string } | null
}

export type UpdateMeMutationVariables = Exact<{
  data: UpdateMeInput
}>

export type UpdateMeMutation = {
  __typename?: 'Mutation'
  updateMe?: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    createdAt: any
    updatedAt: any
    email: string
    token?: string | null
  } | null
}

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser?: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    createdAt: any
    updatedAt: any
    email: string
    token?: string | null
  } | null
}

export type DeleteAccountMutationVariables = Exact<{
  data: DeleteAccountInput
}>

export type DeleteAccountMutation = {
  __typename?: 'Mutation'
  deleteAccount?: { __typename?: 'DeleteAccountResponse'; success: boolean; message?: string | null } | null
}

export type AddToWatchlistMutationVariables = Exact<{
  data: UpdateWatchlistInput
}>

export type AddToWatchlistMutation = {
  __typename?: 'Mutation'
  addToWatchlist?: { __typename?: 'MessageResponse'; message: string } | null
}

export type RemoveFromWatchlistMutationVariables = Exact<{
  data: UpdateWatchlistInput
}>

export type RemoveFromWatchlistMutation = {
  __typename?: 'Mutation'
  removeFromWatchlist?: { __typename?: 'MessageResponse'; message: string } | null
}

export type CreateCheckoutSessionMutationVariables = Exact<{
  data: CreateCheckoutSessionInput
}>

export type CreateCheckoutSessionMutation = {
  __typename?: 'Mutation'
  createCheckoutSession?: { __typename?: 'CheckoutSession'; url?: string | null } | null
}

export type VerifyCheckoutSessionMutationVariables = Exact<{
  data: VerifyCheckoutSessionInput
}>

export type VerifyCheckoutSessionMutation = {
  __typename?: 'Mutation'
  verifyCheckoutSession?: { __typename?: 'MessageResponse'; message: string } | null
}

export type CreatePortalSessionMutationVariables = Exact<{
  data: PortalSessionInput
}>

export type CreatePortalSessionMutation = {
  __typename?: 'Mutation'
  createPortalSession?: { __typename?: 'PortalSession'; url?: string | null } | null
}

export type ReportMutationVariables = Exact<{
  data: ReportInput
}>

export type ReportMutation = {
  __typename?: 'Mutation'
  report?: { __typename?: 'Report'; viewerId?: string | null; viewId: string } | null
}

export type RecordIapMutationVariables = Exact<{
  data: RecordIapInput
}>

export type RecordIapMutation = {
  __typename?: 'Mutation'
  recordIap?: { __typename?: 'MessageResponse'; message: string } | null
}

export type GlobalQueryVariables = Exact<{ [key: string]: never }>

export type GlobalQuery = {
  __typename?: 'Query'
  global?: {
    __typename?: 'Global'
    footer: {
      __typename?: 'Footer'
      description?: string | null
      copyright: string
      links: Array<{
        __typename?: 'Link'
        type: LinkType
        text: string
        url?: string | null
        custom?: boolean | null
        icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        route?: { __typename?: 'Route'; path: string; pageId: string } | null
      } | null>
    }
    header: {
      __typename?: 'Header'
      brand: {
        __typename?: 'Link'
        type: LinkType
        text: string
        url?: string | null
        custom?: boolean | null
        icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        route?: { __typename?: 'Route'; path: string; pageId: string } | null
      }
      links: Array<{
        __typename?: 'Link'
        type: LinkType
        text: string
        url?: string | null
        custom?: boolean | null
        icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        route?: { __typename?: 'Route'; path: string; pageId: string } | null
      }>
      more: Array<{
        __typename?: 'Link'
        type: LinkType
        text: string
        url?: string | null
        custom?: boolean | null
        icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        route?: { __typename?: 'Route'; path: string; pageId: string } | null
      }>
    }
  } | null
}

export type LiveUpcomingQueryVariables = Exact<{ [key: string]: never }>

export type LiveUpcomingQuery = {
  __typename?: 'Query'
  global?: { __typename?: 'Global'; liveUpcoming: boolean } | null
}

export type LiveNowQueryVariables = Exact<{ [key: string]: never }>

export type LiveNowQuery = {
  __typename?: 'Query'
  liveNow?: Array<{
    __typename?: 'Event'
    scheduledEnd?: any | null
    scheduledStart?: any | null
    liveNow: boolean
    id: string
    createdAt: any
    updatedAt: any
    name: string
    subtitle?: string | null
    description?: string | null
    publishOn?: any | null
    expireOn?: any | null
    enabled: boolean
    status: EntryStatus
    downloadsEnabled: boolean
    duration?: number | null
    featured: boolean
    type: ContentType
    favorited?: boolean | null
    purchased?: boolean | null
    restrictions?: Array<ContentRestrictionsType> | null
    appliedGates: Array<{
      __typename?: 'Gate'
      id: string
      type: GateType
      product?: {
        __typename?: 'Product'
        id: string
        name: string
        description?: string | null
        originId: string
        url?: string | null
        unitLabel?: string | null
        images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
        defaultPrice?: {
          __typename?: 'Price'
          id: string
          currency: Currency
          product: string
          amount: number
          type: PriceType
          taxBehavior: TaxBehavior
          iapSkuIos?: string | null
          iapSkuAndroid?: string | null
          recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
        } | null
        prices: Array<{
          __typename?: 'Price'
          id: string
          currency: Currency
          product: string
          amount: number
          type: PriceType
          taxBehavior: TaxBehavior
          iapSkuIos?: string | null
          iapSkuAndroid?: string | null
          recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
        }>
      } | null
    }>
    viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
    logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    categories: Array<{
      __typename?: 'Category'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      description?: string | null
      gateEnabled: boolean
      publishOn?: any | null
      expireOn?: any | null
      enabled: boolean
      status: EntryStatus
      downloadsEnabled: boolean
    }>
    metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
    bundles: Array<{
      __typename?: 'Bundle'
      id: string
      title: string
      description?: string | null
      product?: {
        __typename?: 'Product'
        id: string
        name: string
        description?: string | null
        originId: string
        url?: string | null
        unitLabel?: string | null
        images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
        defaultPrice?: {
          __typename?: 'Price'
          id: string
          currency: Currency
          product: string
          amount: number
          type: PriceType
          taxBehavior: TaxBehavior
          iapSkuIos?: string | null
          iapSkuAndroid?: string | null
          recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
        } | null
        prices: Array<{
          __typename?: 'Price'
          id: string
          currency: Currency
          product: string
          amount: number
          type: PriceType
          taxBehavior: TaxBehavior
          iapSkuIos?: string | null
          iapSkuAndroid?: string | null
          recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
        }>
      } | null
      items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
    }>
  }> | null
}

export type TenantQueryVariables = Exact<{ [key: string]: never }>

export type TenantQuery = { __typename?: 'Query'; tenant?: { __typename?: 'Tenant'; id: string; name: string } | null }

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    roles: Array<Role>
    customer?: {
      __typename?: 'Customer'
      id?: string | null
      subscriptions: Array<{
        __typename?: 'Subscription'
        id?: string | null
        status: SubscriptionStatus
        items: Array<{
          __typename?: 'SubscriptionItem'
          id?: string | null
          price: {
            __typename?: 'Price'
            id: string
            currency: Currency
            product: string
            amount: number
            type: PriceType
            taxBehavior: TaxBehavior
            iapSkuIos?: string | null
            iapSkuAndroid?: string | null
            recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
          }
        }>
      }>
    } | null
  } | null
}

export type MediaItemQueryVariables = Exact<{
  data: RecordByIdInput
}>

export type MediaItemQuery = {
  __typename?: 'Query'
  mediaItem?:
    | {
        __typename?: 'Event'
        scheduledEnd?: any | null
        scheduledStart?: any | null
        liveNow: boolean
        id: string
        createdAt: any
        updatedAt: any
        name: string
        subtitle?: string | null
        description?: string | null
        publishOn?: any | null
        expireOn?: any | null
        enabled: boolean
        status: EntryStatus
        downloadsEnabled: boolean
        duration?: number | null
        featured: boolean
        type: ContentType
        favorited?: boolean | null
        purchased?: boolean | null
        restrictions?: Array<ContentRestrictionsType> | null
        appliedGates: Array<{
          __typename?: 'Gate'
          id: string
          type: GateType
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
        }>
        viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
        logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        categories: Array<{
          __typename?: 'Category'
          id: string
          createdAt: any
          updatedAt: any
          name: string
          description?: string | null
          gateEnabled: boolean
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
        }>
        metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
        bundles: Array<{
          __typename?: 'Bundle'
          id: string
          title: string
          description?: string | null
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
          items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
        }>
      }
    | {
        __typename?: 'Video'
        id: string
        createdAt: any
        updatedAt: any
        name: string
        subtitle?: string | null
        description?: string | null
        publishOn?: any | null
        expireOn?: any | null
        enabled: boolean
        status: EntryStatus
        downloadsEnabled: boolean
        duration?: number | null
        featured: boolean
        type: ContentType
        favorited?: boolean | null
        purchased?: boolean | null
        restrictions?: Array<ContentRestrictionsType> | null
        appliedGates: Array<{
          __typename?: 'Gate'
          id: string
          type: GateType
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
        }>
        viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
        logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
        categories: Array<{
          __typename?: 'Category'
          id: string
          createdAt: any
          updatedAt: any
          name: string
          description?: string | null
          gateEnabled: boolean
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
        }>
        metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
        bundles: Array<{
          __typename?: 'Bundle'
          id: string
          title: string
          description?: string | null
          product?: {
            __typename?: 'Product'
            id: string
            name: string
            description?: string | null
            originId: string
            url?: string | null
            unitLabel?: string | null
            images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
            defaultPrice?: {
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            } | null
            prices: Array<{
              __typename?: 'Price'
              id: string
              currency: Currency
              product: string
              amount: number
              type: PriceType
              taxBehavior: TaxBehavior
              iapSkuIos?: string | null
              iapSkuAndroid?: string | null
              recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
            }>
          } | null
          items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
        }>
      }
    | null
}

export type HomeQueryVariables = Exact<{ [key: string]: never }>

export type HomeQuery = {
  __typename?: 'Query'
  home?: {
    __typename?: 'Home'
    featured: Array<
      | {
          __typename?: 'Event'
          scheduledEnd?: any | null
          scheduledStart?: any | null
          liveNow: boolean
          id: string
          createdAt: any
          updatedAt: any
          name: string
          subtitle?: string | null
          description?: string | null
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
          duration?: number | null
          featured: boolean
          type: ContentType
          favorited?: boolean | null
          purchased?: boolean | null
          restrictions?: Array<ContentRestrictionsType> | null
          appliedGates: Array<{
            __typename?: 'Gate'
            id: string
            type: GateType
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
          }>
          viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
          logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          categories: Array<{
            __typename?: 'Category'
            id: string
            createdAt: any
            updatedAt: any
            name: string
            description?: string | null
            gateEnabled: boolean
            publishOn?: any | null
            expireOn?: any | null
            enabled: boolean
            status: EntryStatus
            downloadsEnabled: boolean
          }>
          metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
          bundles: Array<{
            __typename?: 'Bundle'
            id: string
            title: string
            description?: string | null
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
            items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
          }>
        }
      | {
          __typename?: 'Video'
          id: string
          createdAt: any
          updatedAt: any
          name: string
          subtitle?: string | null
          description?: string | null
          publishOn?: any | null
          expireOn?: any | null
          enabled: boolean
          status: EntryStatus
          downloadsEnabled: boolean
          duration?: number | null
          featured: boolean
          type: ContentType
          favorited?: boolean | null
          purchased?: boolean | null
          restrictions?: Array<ContentRestrictionsType> | null
          appliedGates: Array<{
            __typename?: 'Gate'
            id: string
            type: GateType
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
          }>
          viewer?: { __typename?: 'Viewer'; lastViewedAt: any; lastPosition: number; progress?: number | null } | null
          logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          sixteenNineCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          twoThreeCover?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
          categories: Array<{
            __typename?: 'Category'
            id: string
            createdAt: any
            updatedAt: any
            name: string
            description?: string | null
            gateEnabled: boolean
            publishOn?: any | null
            expireOn?: any | null
            enabled: boolean
            status: EntryStatus
            downloadsEnabled: boolean
          }>
          metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
          bundles: Array<{
            __typename?: 'Bundle'
            id: string
            title: string
            description?: string | null
            product?: {
              __typename?: 'Product'
              id: string
              name: string
              description?: string | null
              originId: string
              url?: string | null
              unitLabel?: string | null
              images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
              defaultPrice?: {
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              } | null
              prices: Array<{
                __typename?: 'Price'
                id: string
                currency: Currency
                product: string
                amount: number
                type: PriceType
                taxBehavior: TaxBehavior
                iapSkuIos?: string | null
                iapSkuAndroid?: string | null
                recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
              }>
            } | null
            items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
          }>
        }
      | null
    >
    rows: Array<{
      __typename?: 'MediaRow'
      id: string
      title: string
      type: MediaRowType
      catalogId?: string | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      items: {
        __typename?: 'MediaItemResponse'
        total: number
        hasMore: boolean
        list: Array<
          | {
              __typename?: 'Event'
              scheduledEnd?: any | null
              scheduledStart?: any | null
              liveNow: boolean
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
          | {
              __typename?: 'Video'
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
        >
      }
    }>
  } | null
}

export type PlayerQueryVariables = Exact<{
  data: PlayerInput
}>

export type PlayerQuery = {
  __typename?: 'Query'
  player?: { __typename?: 'Player'; signedUrl: string; url: string; position?: number | null; contentId: string } | null
}

export type PageQueryVariables = Exact<{
  data: SinglePageInput
}>

export type PageQuery = {
  __typename?: 'Query'
  page?: {
    __typename?: 'Page'
    id: string
    name: string
    slug: string
    description?: string | null
    bannerImage?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
    rows: Array<{
      __typename?: 'MediaRow'
      id: string
      title: string
      type: MediaRowType
      catalogId?: string | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      items: {
        __typename?: 'MediaItemResponse'
        total: number
        hasMore: boolean
        list: Array<
          | {
              __typename?: 'Event'
              scheduledEnd?: any | null
              scheduledStart?: any | null
              liveNow: boolean
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
          | {
              __typename?: 'Video'
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
        >
      }
    }>
  } | null
}

export type AllQueryVariables = Exact<{
  rowData: MediaRowInput
  itemData: MediaItemInput
}>

export type AllQuery = {
  __typename?: 'Query'
  all?: {
    __typename?: 'All'
    row: {
      __typename?: 'MediaRow'
      id: string
      title: string
      type: MediaRowType
      catalogId?: string | null
      icon?: {
        __typename?: 'Image'
        src: string
        width?: number | null
        height?: number | null
        set?: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }> | null
      } | null
      items: {
        __typename?: 'MediaItemResponse'
        total: number
        hasMore: boolean
        list: Array<
          | {
              __typename?: 'Event'
              scheduledEnd?: any | null
              scheduledStart?: any | null
              liveNow: boolean
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
          | {
              __typename?: 'Video'
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
        >
      }
    }
  } | null
}

export type ExploreQueryVariables = Exact<{
  mediaRowData?: InputMaybe<ExploreInput>
  mediaItemData?: InputMaybe<MediaItemInput>
}>

export type ExploreQuery = {
  __typename?: 'Query'
  explore?: {
    __typename?: 'Explore'
    rows: Array<{
      __typename?: 'MediaRow'
      id: string
      title: string
      type: MediaRowType
      catalogId?: string | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      items: {
        __typename?: 'MediaItemResponse'
        total: number
        hasMore: boolean
        list: Array<
          | {
              __typename?: 'Event'
              scheduledEnd?: any | null
              scheduledStart?: any | null
              liveNow: boolean
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
          | {
              __typename?: 'Video'
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
        >
      }
    } | null>
  } | null
}

export type MyLibraryQueryVariables = Exact<{ [key: string]: never }>

export type MyLibraryQuery = {
  __typename?: 'Query'
  myLibrary?: {
    __typename?: 'MyLibrary'
    rows: Array<{
      __typename?: 'MediaRow'
      id: string
      title: string
      type: MediaRowType
      catalogId?: string | null
      icon?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
      items: {
        __typename?: 'MediaItemResponse'
        total: number
        hasMore: boolean
        list: Array<
          | {
              __typename?: 'Event'
              scheduledEnd?: any | null
              scheduledStart?: any | null
              liveNow: boolean
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
          | {
              __typename?: 'Video'
              id: string
              createdAt: any
              updatedAt: any
              name: string
              subtitle?: string | null
              description?: string | null
              publishOn?: any | null
              expireOn?: any | null
              enabled: boolean
              status: EntryStatus
              downloadsEnabled: boolean
              duration?: number | null
              featured: boolean
              type: ContentType
              favorited?: boolean | null
              purchased?: boolean | null
              restrictions?: Array<ContentRestrictionsType> | null
              appliedGates: Array<{
                __typename?: 'Gate'
                id: string
                type: GateType
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
              }>
              viewer?: {
                __typename?: 'Viewer'
                lastViewedAt: any
                lastPosition: number
                progress?: number | null
              } | null
              logo?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              sixteenNineCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              twoThreeCover?: {
                __typename?: 'Image'
                src: string
                width?: number | null
                height?: number | null
              } | null
              tallBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              wideBackdrop?: { __typename?: 'Image'; src: string; width?: number | null; height?: number | null } | null
              categories: Array<{
                __typename?: 'Category'
                id: string
                createdAt: any
                updatedAt: any
                name: string
                description?: string | null
                gateEnabled: boolean
                publishOn?: any | null
                expireOn?: any | null
                enabled: boolean
                status: EntryStatus
                downloadsEnabled: boolean
              }>
              metadata: Array<{ __typename?: 'Metadata'; name: string; value?: string | null }>
              bundles: Array<{
                __typename?: 'Bundle'
                id: string
                title: string
                description?: string | null
                product?: {
                  __typename?: 'Product'
                  id: string
                  name: string
                  description?: string | null
                  originId: string
                  url?: string | null
                  unitLabel?: string | null
                  images: Array<{ __typename?: 'Image'; src: string; width?: number | null; height?: number | null }>
                  defaultPrice?: {
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  } | null
                  prices: Array<{
                    __typename?: 'Price'
                    id: string
                    currency: Currency
                    product: string
                    amount: number
                    type: PriceType
                    taxBehavior: TaxBehavior
                    iapSkuIos?: string | null
                    iapSkuAndroid?: string | null
                    recurring?: { __typename?: 'Recurring'; interval: Interval; intervalCount: number } | null
                  }>
                } | null
                items: Array<{ __typename?: 'Event'; name: string } | { __typename?: 'Video'; name: string }>
              }>
            }
        >
      }
    } | null>
  } | null
}

export const ImageFieldsFragmentDoc = gql`
  fragment imageFields on Image {
    src
    width
    height
  }
`
export const RouteFieldsFragmentDoc = gql`
  fragment routeFields on Route {
    path
    pageId
  }
`
export const LinkFieldsFragmentDoc = gql`
  fragment linkFields on Link {
    type
    text
    icon {
      ...imageFields
    }
    url
    route {
      ...routeFields
    }
    custom
  }
  ${ImageFieldsFragmentDoc}
  ${RouteFieldsFragmentDoc}
`
export const FooterFieldsFragmentDoc = gql`
  fragment footerFields on Footer {
    description
    copyright
    links {
      ...linkFields
    }
  }
  ${LinkFieldsFragmentDoc}
`
export const HeaderFieldsFragmentDoc = gql`
  fragment headerFields on Header {
    brand {
      ...linkFields
    }
    links {
      ...linkFields
    }
    more {
      ...linkFields
    }
  }
  ${LinkFieldsFragmentDoc}
`
export const GlobalFieldsFragmentDoc = gql`
  fragment globalFields on Global {
    footer {
      ...footerFields
    }
    header {
      ...headerFields
    }
  }
  ${FooterFieldsFragmentDoc}
  ${HeaderFieldsFragmentDoc}
`
export const UserFieldsFragmentDoc = gql`
  fragment userFields on User {
    id
    firstName
    lastName
    createdAt
    updatedAt
    email
    token
  }
`
export const RecurringFieldsFragmentDoc = gql`
  fragment recurringFields on Recurring {
    interval
    intervalCount
  }
`
export const PriceFieldsFragmentDoc = gql`
  fragment priceFields on Price {
    id
    currency
    recurring {
      ...recurringFields
    }
    product
    amount
    type
    taxBehavior
    iapSkuIos
    iapSkuAndroid
  }
  ${RecurringFieldsFragmentDoc}
`
export const SubscriptionItemFieldsFragmentDoc = gql`
  fragment subscriptionItemFields on SubscriptionItem {
    id
    price {
      ...priceFields
    }
  }
  ${PriceFieldsFragmentDoc}
`
export const SubscriptionFieldsFragmentDoc = gql`
  fragment subscriptionFields on Subscription {
    id
    status
    items {
      ...subscriptionItemFields
    }
  }
  ${SubscriptionItemFieldsFragmentDoc}
`
export const CustomerFieldsFragmentDoc = gql`
  fragment customerFields on Customer {
    id
    subscriptions {
      ...subscriptionFields
    }
  }
  ${SubscriptionFieldsFragmentDoc}
`
export const ChannelFieldsFragmentDoc = gql`
  fragment channelFields on Channel {
    id
    name
    description
  }
`
export const PlayerFieldsFragmentDoc = gql`
  fragment playerFields on Player {
    signedUrl
    url
    position
    contentId
  }
`
export const ProductFieldsFragmentDoc = gql`
  fragment productFields on Product {
    id
    name
    description
    originId
    url
    images {
      ...imageFields
    }
    defaultPrice {
      ...priceFields
    }
    prices {
      ...priceFields
    }
    unitLabel
  }
  ${ImageFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
`
export const GateFieldsFragmentDoc = gql`
  fragment gateFields on Gate {
    id
    type
    product {
      ...productFields
    }
  }
  ${ProductFieldsFragmentDoc}
`
export const ViewerFieldsFragmentDoc = gql`
  fragment viewerFields on Viewer {
    lastViewedAt
    lastPosition
    progress
  }
`
export const CategoryFieldsFragmentDoc = gql`
  fragment categoryFields on Category {
    id
    createdAt
    updatedAt
    name
    description
    gateEnabled
    publishOn
    expireOn
    enabled
    status
    downloadsEnabled
  }
`
export const MetadataFieldsFragmentDoc = gql`
  fragment metadataFields on Metadata {
    name
    value
  }
`
export const ContentFieldsFragmentDoc = gql`
  fragment contentFields on Content {
    id
    createdAt
    updatedAt
    name
    subtitle
    description
    appliedGates {
      ...gateFields
    }
    publishOn
    expireOn
    enabled
    status
    downloadsEnabled
    duration
    viewer {
      ...viewerFields
    }
    logo {
      ...imageFields
    }
    sixteenNineCover {
      ...imageFields
    }
    twoThreeCover {
      ...imageFields
    }
    tallBackdrop {
      ...imageFields
    }
    wideBackdrop {
      ...imageFields
    }
    featured
    type
    categories {
      ...categoryFields
    }
    metadata {
      ...metadataFields
    }
    favorited
    purchased
    restrictions
    bundles {
      id
      title
      description
      product {
        ...productFields
      }
      items {
        ... on Video {
          name
        }
        ... on Event {
          name
        }
      }
    }
  }
  ${GateFieldsFragmentDoc}
  ${ViewerFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${CategoryFieldsFragmentDoc}
  ${MetadataFieldsFragmentDoc}
  ${ProductFieldsFragmentDoc}
`
export const VideoFieldsFragmentDoc = gql`
  fragment videoFields on Video {
    ...contentFields
  }
  ${ContentFieldsFragmentDoc}
`
export const EventFieldsFragmentDoc = gql`
  fragment eventFields on Event {
    ...contentFields
    scheduledEnd
    scheduledStart
    liveNow
  }
  ${ContentFieldsFragmentDoc}
`
export const MediaItemFieldsFragmentDoc = gql`
  fragment mediaItemFields on MediaItem {
    ... on Video {
      ...videoFields
    }
    ... on Event {
      ...eventFields
    }
  }
  ${VideoFieldsFragmentDoc}
  ${EventFieldsFragmentDoc}
`
export const MediaItemResponseFieldsFragmentDoc = gql`
  fragment mediaItemResponseFields on MediaItemResponse {
    total
    hasMore
    list {
      ...mediaItemFields
    }
  }
  ${MediaItemFieldsFragmentDoc}
`
export const MediaRowFieldsFragmentDoc = gql`
  fragment mediaRowFields on MediaRow {
    id
    title
    icon {
      ...imageFields
    }
    type
    catalogId
    items {
      ...mediaItemResponseFields
    }
  }
  ${ImageFieldsFragmentDoc}
  ${MediaItemResponseFieldsFragmentDoc}
`
export const PageFieldsFragmentDoc = gql`
  fragment pageFields on Page {
    id
    name
    slug
    description
    bannerImage {
      ...imageFields
    }
    rows {
      ...mediaRowFields
    }
  }
  ${ImageFieldsFragmentDoc}
  ${MediaRowFieldsFragmentDoc}
`
export const LoginDocument = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      token
      firstName
      lastName
      email
      roles
      customer {
        ...customerFields
      }
    }
  }
  ${CustomerFieldsFragmentDoc}
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>
export const RegisterTenantDocument = gql`
  mutation RegisterTenant($data: RegisterTenantInput!) {
    registerTenant(data: $data) {
      name
      id
      api {
        type
      }
      owner {
        firstName
        lastName
        email
        roles
      }
    }
  }
`
export type RegisterTenantMutationFn = Apollo.MutationFunction<RegisterTenantMutation, RegisterTenantMutationVariables>

/**
 * __useRegisterTenantMutation__
 *
 * To run a mutation, you first call `useRegisterTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTenantMutation, { data, loading, error }] = useRegisterTenantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterTenantMutation, RegisterTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterTenantMutation, RegisterTenantMutationVariables>(RegisterTenantDocument, options)
}
export type RegisterTenantMutationHookResult = ReturnType<typeof useRegisterTenantMutation>
export type RegisterTenantMutationResult = Apollo.MutationResult<RegisterTenantMutation>
export type RegisterTenantMutationOptions = Apollo.BaseMutationOptions<
  RegisterTenantMutation,
  RegisterTenantMutationVariables
>
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      message
    }
  }
`
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options)
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation ResetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      message
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      message
    }
  }
`
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options)
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>
export const UpdateMeDocument = gql`
  mutation UpdateMe($data: UpdateMeInput!) {
    updateMe(data: $data) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options)
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>
export const CreateUserDocument = gql`
  mutation CreateUser($data: CreateUserInput!) {
    createUser(data: $data) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options)
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($data: DeleteAccountInput!) {
    deleteAccount(data: $data) {
      success
      message
    }
  }
`
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options)
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>
export const AddToWatchlistDocument = gql`
  mutation AddToWatchlist($data: UpdateWatchlistInput!) {
    addToWatchlist(data: $data) {
      message
    }
  }
`
export type AddToWatchlistMutationFn = Apollo.MutationFunction<AddToWatchlistMutation, AddToWatchlistMutationVariables>

/**
 * __useAddToWatchlistMutation__
 *
 * To run a mutation, you first call `useAddToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWatchlistMutation, { data, loading, error }] = useAddToWatchlistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddToWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToWatchlistMutation, AddToWatchlistMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddToWatchlistMutation, AddToWatchlistMutationVariables>(AddToWatchlistDocument, options)
}
export type AddToWatchlistMutationHookResult = ReturnType<typeof useAddToWatchlistMutation>
export type AddToWatchlistMutationResult = Apollo.MutationResult<AddToWatchlistMutation>
export type AddToWatchlistMutationOptions = Apollo.BaseMutationOptions<
  AddToWatchlistMutation,
  AddToWatchlistMutationVariables
>
export const RemoveFromWatchlistDocument = gql`
  mutation RemoveFromWatchlist($data: UpdateWatchlistInput!) {
    removeFromWatchlist(data: $data) {
      message
    }
  }
`
export type RemoveFromWatchlistMutationFn = Apollo.MutationFunction<
  RemoveFromWatchlistMutation,
  RemoveFromWatchlistMutationVariables
>

/**
 * __useRemoveFromWatchlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWatchlistMutation, { data, loading, error }] = useRemoveFromWatchlistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveFromWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveFromWatchlistMutation, RemoveFromWatchlistMutationVariables>(
    RemoveFromWatchlistDocument,
    options,
  )
}
export type RemoveFromWatchlistMutationHookResult = ReturnType<typeof useRemoveFromWatchlistMutation>
export type RemoveFromWatchlistMutationResult = Apollo.MutationResult<RemoveFromWatchlistMutation>
export type RemoveFromWatchlistMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromWatchlistMutation,
  RemoveFromWatchlistMutationVariables
>
export const CreateCheckoutSessionDocument = gql`
  mutation CreateCheckoutSession($data: CreateCheckoutSessionInput!) {
    createCheckoutSession(data: $data) {
      url
    }
  }
`
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<
  CreateCheckoutSessionMutation,
  CreateCheckoutSessionMutationVariables
>

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(
    CreateCheckoutSessionDocument,
    options,
  )
}
export type CreateCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSessionMutation>
export type CreateCheckoutSessionMutationResult = Apollo.MutationResult<CreateCheckoutSessionMutation>
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateCheckoutSessionMutation,
  CreateCheckoutSessionMutationVariables
>
export const VerifyCheckoutSessionDocument = gql`
  mutation VerifyCheckoutSession($data: VerifyCheckoutSessionInput!) {
    verifyCheckoutSession(data: $data) {
      message
    }
  }
`
export type VerifyCheckoutSessionMutationFn = Apollo.MutationFunction<
  VerifyCheckoutSessionMutation,
  VerifyCheckoutSessionMutationVariables
>

/**
 * __useVerifyCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useVerifyCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCheckoutSessionMutation, { data, loading, error }] = useVerifyCheckoutSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyCheckoutSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyCheckoutSessionMutation, VerifyCheckoutSessionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyCheckoutSessionMutation, VerifyCheckoutSessionMutationVariables>(
    VerifyCheckoutSessionDocument,
    options,
  )
}
export type VerifyCheckoutSessionMutationHookResult = ReturnType<typeof useVerifyCheckoutSessionMutation>
export type VerifyCheckoutSessionMutationResult = Apollo.MutationResult<VerifyCheckoutSessionMutation>
export type VerifyCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<
  VerifyCheckoutSessionMutation,
  VerifyCheckoutSessionMutationVariables
>
export const CreatePortalSessionDocument = gql`
  mutation CreatePortalSession($data: PortalSessionInput!) {
    createPortalSession(data: $data) {
      url
    }
  }
`
export type CreatePortalSessionMutationFn = Apollo.MutationFunction<
  CreatePortalSessionMutation,
  CreatePortalSessionMutationVariables
>

/**
 * __useCreatePortalSessionMutation__
 *
 * To run a mutation, you first call `useCreatePortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortalSessionMutation, { data, loading, error }] = useCreatePortalSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePortalSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePortalSessionMutation, CreatePortalSessionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePortalSessionMutation, CreatePortalSessionMutationVariables>(
    CreatePortalSessionDocument,
    options,
  )
}
export type CreatePortalSessionMutationHookResult = ReturnType<typeof useCreatePortalSessionMutation>
export type CreatePortalSessionMutationResult = Apollo.MutationResult<CreatePortalSessionMutation>
export type CreatePortalSessionMutationOptions = Apollo.BaseMutationOptions<
  CreatePortalSessionMutation,
  CreatePortalSessionMutationVariables
>
export const ReportDocument = gql`
  mutation Report($data: ReportInput!) {
    report(data: $data) {
      viewerId
      viewId
    }
  }
`
export type ReportMutationFn = Apollo.MutationFunction<ReportMutation, ReportMutationVariables>

/**
 * __useReportMutation__
 *
 * To run a mutation, you first call `useReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMutation, { data, loading, error }] = useReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportMutation(baseOptions?: Apollo.MutationHookOptions<ReportMutation, ReportMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReportMutation, ReportMutationVariables>(ReportDocument, options)
}
export type ReportMutationHookResult = ReturnType<typeof useReportMutation>
export type ReportMutationResult = Apollo.MutationResult<ReportMutation>
export type ReportMutationOptions = Apollo.BaseMutationOptions<ReportMutation, ReportMutationVariables>
export const RecordIapDocument = gql`
  mutation RecordIap($data: RecordIapInput!) {
    recordIap(data: $data) {
      message
    }
  }
`
export type RecordIapMutationFn = Apollo.MutationFunction<RecordIapMutation, RecordIapMutationVariables>

/**
 * __useRecordIapMutation__
 *
 * To run a mutation, you first call `useRecordIapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordIapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordIapMutation, { data, loading, error }] = useRecordIapMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecordIapMutation(
  baseOptions?: Apollo.MutationHookOptions<RecordIapMutation, RecordIapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecordIapMutation, RecordIapMutationVariables>(RecordIapDocument, options)
}
export type RecordIapMutationHookResult = ReturnType<typeof useRecordIapMutation>
export type RecordIapMutationResult = Apollo.MutationResult<RecordIapMutation>
export type RecordIapMutationOptions = Apollo.BaseMutationOptions<RecordIapMutation, RecordIapMutationVariables>
export const GlobalDocument = gql`
  query Global {
    global {
      ...globalFields
    }
  }
  ${GlobalFieldsFragmentDoc}
`

/**
 * __useGlobalQuery__
 *
 * To run a query within a React component, call `useGlobalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalQuery(baseOptions?: Apollo.QueryHookOptions<GlobalQuery, GlobalQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GlobalQuery, GlobalQueryVariables>(GlobalDocument, options)
}
export function useGlobalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalQuery, GlobalQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GlobalQuery, GlobalQueryVariables>(GlobalDocument, options)
}
export type GlobalQueryHookResult = ReturnType<typeof useGlobalQuery>
export type GlobalLazyQueryHookResult = ReturnType<typeof useGlobalLazyQuery>
export type GlobalQueryResult = Apollo.QueryResult<GlobalQuery, GlobalQueryVariables>
export const LiveUpcomingDocument = gql`
  query LiveUpcoming {
    global {
      liveUpcoming
    }
  }
`

/**
 * __useLiveUpcomingQuery__
 *
 * To run a query within a React component, call `useLiveUpcomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveUpcomingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveUpcomingQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveUpcomingQuery(
  baseOptions?: Apollo.QueryHookOptions<LiveUpcomingQuery, LiveUpcomingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LiveUpcomingQuery, LiveUpcomingQueryVariables>(LiveUpcomingDocument, options)
}
export function useLiveUpcomingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LiveUpcomingQuery, LiveUpcomingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LiveUpcomingQuery, LiveUpcomingQueryVariables>(LiveUpcomingDocument, options)
}
export type LiveUpcomingQueryHookResult = ReturnType<typeof useLiveUpcomingQuery>
export type LiveUpcomingLazyQueryHookResult = ReturnType<typeof useLiveUpcomingLazyQuery>
export type LiveUpcomingQueryResult = Apollo.QueryResult<LiveUpcomingQuery, LiveUpcomingQueryVariables>
export const LiveNowDocument = gql`
  query LiveNow {
    liveNow {
      ...eventFields
    }
  }
  ${EventFieldsFragmentDoc}
`

/**
 * __useLiveNowQuery__
 *
 * To run a query within a React component, call `useLiveNowQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveNowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveNowQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveNowQuery(baseOptions?: Apollo.QueryHookOptions<LiveNowQuery, LiveNowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LiveNowQuery, LiveNowQueryVariables>(LiveNowDocument, options)
}
export function useLiveNowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveNowQuery, LiveNowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LiveNowQuery, LiveNowQueryVariables>(LiveNowDocument, options)
}
export type LiveNowQueryHookResult = ReturnType<typeof useLiveNowQuery>
export type LiveNowLazyQueryHookResult = ReturnType<typeof useLiveNowLazyQuery>
export type LiveNowQueryResult = Apollo.QueryResult<LiveNowQuery, LiveNowQueryVariables>
export const TenantDocument = gql`
  query Tenant {
    tenant {
      id
      name
    }
  }
`

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantQuery(baseOptions?: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options)
}
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options)
}
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>
export const MeDocument = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      roles
      customer {
        ...customerFields
      }
    }
  }
  ${CustomerFieldsFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const MediaItemDocument = gql`
  query MediaItem($data: RecordByIdInput!) {
    mediaItem(data: $data) {
      ...mediaItemFields
    }
  }
  ${MediaItemFieldsFragmentDoc}
`

/**
 * __useMediaItemQuery__
 *
 * To run a query within a React component, call `useMediaItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaItemQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMediaItemQuery(baseOptions: Apollo.QueryHookOptions<MediaItemQuery, MediaItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MediaItemQuery, MediaItemQueryVariables>(MediaItemDocument, options)
}
export function useMediaItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaItemQuery, MediaItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MediaItemQuery, MediaItemQueryVariables>(MediaItemDocument, options)
}
export type MediaItemQueryHookResult = ReturnType<typeof useMediaItemQuery>
export type MediaItemLazyQueryHookResult = ReturnType<typeof useMediaItemLazyQuery>
export type MediaItemQueryResult = Apollo.QueryResult<MediaItemQuery, MediaItemQueryVariables>
export const HomeDocument = gql`
  query Home {
    home {
      featured {
        ...mediaItemFields
      }
      rows {
        ...mediaRowFields
      }
    }
  }
  ${MediaItemFieldsFragmentDoc}
  ${MediaRowFieldsFragmentDoc}
`

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options)
}
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options)
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>
export const PlayerDocument = gql`
  query Player($data: PlayerInput!) {
    player(data: $data) {
      ...playerFields
    }
  }
  ${PlayerFieldsFragmentDoc}
`

/**
 * __usePlayerQuery__
 *
 * To run a query within a React component, call `usePlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlayerQuery(baseOptions: Apollo.QueryHookOptions<PlayerQuery, PlayerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PlayerQuery, PlayerQueryVariables>(PlayerDocument, options)
}
export function usePlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerQuery, PlayerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PlayerQuery, PlayerQueryVariables>(PlayerDocument, options)
}
export type PlayerQueryHookResult = ReturnType<typeof usePlayerQuery>
export type PlayerLazyQueryHookResult = ReturnType<typeof usePlayerLazyQuery>
export type PlayerQueryResult = Apollo.QueryResult<PlayerQuery, PlayerQueryVariables>
export const PageDocument = gql`
  query Page($data: SinglePageInput!) {
    page(data: $data) {
      ...pageFields
    }
  }
  ${PageFieldsFragmentDoc}
`

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options)
}
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options)
}
export type PageQueryHookResult = ReturnType<typeof usePageQuery>
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>
export const AllDocument = gql`
  query All($rowData: MediaRowInput!, $itemData: MediaItemInput!) {
    all {
      row(data: $rowData) {
        id
        title
        icon {
          src
          width
          height
          set {
            src
            width
            height
          }
        }
        type
        catalogId
        items(data: $itemData) {
          total
          hasMore
          list {
            ...mediaItemFields
          }
        }
      }
    }
  }
  ${MediaItemFieldsFragmentDoc}
`

/**
 * __useAllQuery__
 *
 * To run a query within a React component, call `useAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllQuery({
 *   variables: {
 *      rowData: // value for 'rowData'
 *      itemData: // value for 'itemData'
 *   },
 * });
 */
export function useAllQuery(baseOptions: Apollo.QueryHookOptions<AllQuery, AllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllQuery, AllQueryVariables>(AllDocument, options)
}
export function useAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllQuery, AllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllQuery, AllQueryVariables>(AllDocument, options)
}
export type AllQueryHookResult = ReturnType<typeof useAllQuery>
export type AllLazyQueryHookResult = ReturnType<typeof useAllLazyQuery>
export type AllQueryResult = Apollo.QueryResult<AllQuery, AllQueryVariables>
export const ExploreDocument = gql`
  query Explore($mediaRowData: ExploreInput, $mediaItemData: MediaItemInput) {
    explore {
      rows(data: $mediaRowData) {
        id
        title
        icon {
          ...imageFields
        }
        type
        catalogId
        items(data: $mediaItemData) {
          ...mediaItemResponseFields
        }
      }
    }
  }
  ${ImageFieldsFragmentDoc}
  ${MediaItemResponseFieldsFragmentDoc}
`

/**
 * __useExploreQuery__
 *
 * To run a query within a React component, call `useExploreQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreQuery({
 *   variables: {
 *      mediaRowData: // value for 'mediaRowData'
 *      mediaItemData: // value for 'mediaItemData'
 *   },
 * });
 */
export function useExploreQuery(baseOptions?: Apollo.QueryHookOptions<ExploreQuery, ExploreQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ExploreQuery, ExploreQueryVariables>(ExploreDocument, options)
}
export function useExploreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExploreQuery, ExploreQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ExploreQuery, ExploreQueryVariables>(ExploreDocument, options)
}
export type ExploreQueryHookResult = ReturnType<typeof useExploreQuery>
export type ExploreLazyQueryHookResult = ReturnType<typeof useExploreLazyQuery>
export type ExploreQueryResult = Apollo.QueryResult<ExploreQuery, ExploreQueryVariables>
export const MyLibraryDocument = gql`
  query MyLibrary {
    myLibrary {
      rows {
        ...mediaRowFields
      }
    }
  }
  ${MediaRowFieldsFragmentDoc}
`

/**
 * __useMyLibraryQuery__
 *
 * To run a query within a React component, call `useMyLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyLibraryQuery(baseOptions?: Apollo.QueryHookOptions<MyLibraryQuery, MyLibraryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyLibraryQuery, MyLibraryQueryVariables>(MyLibraryDocument, options)
}
export function useMyLibraryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyLibraryQuery, MyLibraryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyLibraryQuery, MyLibraryQueryVariables>(MyLibraryDocument, options)
}
export type MyLibraryQueryHookResult = ReturnType<typeof useMyLibraryQuery>
export type MyLibraryLazyQueryHookResult = ReturnType<typeof useMyLibraryLazyQuery>
export type MyLibraryQueryResult = Apollo.QueryResult<MyLibraryQuery, MyLibraryQueryVariables>
